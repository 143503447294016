import * as userService from "../../../Services/API/api.service";
import { toast } from "../../../Components/Toast/toast.component";
import * as types from "../../../Constants/_Types/types.reduxStore";
import { startLoader, stopLoader } from "../Loader/loader.action";
import { BASEURL, SUCCESS_MESSAGE } from "../../../Constants/constant";
import editSubadmin from "../../../Components/pages/subAdmin/editSubadmin";

//import { reset } from 'redux-form';

const tempSignInDetails = (token, data) => {
  return {
    type: types.TEMP_SIGN_IN,
    payload: {
      tokens: token,
      userInfo: data,
    },
  };
};

const signInDetails = (token, data) => {
  var jwt = require("jsonwebtoken");
  var tokensData = jwt.decode(token);
  return {
    type: types.SIGN_IN,
    payload: {
      tokens: token,
      userInfo: data,
      // encryptUserInfo: tokensData
    },
  };
};

const deviceAuthenticate = (id) => {
  return {
    type: types.DEVICE_AUTHENTICATE,
    payload: {
      deviceVerification: id,
    },
  };
};

const get2fastatus = (twoFastatusData) => {
  return {
    type: types.TWO_FASTATUS,
    payload: {
      twoFastatusData: twoFastatusData,
    },
  };
};

const google2faAuthenticate = (token) => {
  return {
    type: types.TWO_FA_AUTHENTICATE,
    payload: {
      token: token,
    },
  };
};

const logout = () => {
  return {
    type: types.SIGN_OUT,
  };
};

const usersList = (usersList, totalRecords) => {
  return {
    type: types.USERS_LIST,
    payload: {
      usersList: usersList,
      totalRecords: totalRecords,
    },
  };
};

const activeUsers = (activeUsers) => {
  return {
    type: types.ACTIVE_USERS,
    payload: {
      activeUsers: activeUsers,
    },
  };
};

const statisticsData = (statisticsData) => {
  return {
    type: types.STATISTICS_DATA,
    payload: {
      statisticsData: statisticsData,
    },
  };
};

const downlineData = (downlineData) => {
  return {
    type: types.DOWNLINE,
    payload: {
      downlineData: downlineData,
    },
  };
};

const singleTraderData = (singleTraderData) => {
  return {
    type: types.SINGLE_TRADER_DATA,
    payload: {
      singleTraderData: singleTraderData,
    },
  };
};

const usersWithdrawData = (usersWithdrawData) => {
  return {
    type: types.USER_WITHDRAD_TRANS,
    payload: {
      usersWithdrawData: usersWithdrawData,
    },
  };
};

const ethBalance = (ethBalance) => {
  return {
    type: types.ETH_BALANCE,
    payload: {
      ethBalance: ethBalance,
    },
  };
};

const getuserBalance = (balance) => {
  return {
    type: types.USER_GET_BALANCE,
    payload: {
      userBalance: balance,
    },
  };
};

const btcBalance = (btcBalance) => {
  return {
    type: types.BTC_BALANCE,
    payload: {
      btcBalance: btcBalance,
    },
  };
};

const usdtBalance = (usdtBalance) => {
  return {
    type: types.USDT_BALANCE,
    payload: {
      usdtBalance: usdtBalance,
    },
  };
};

const bchBalance = (bchBalance) => {
  return {
    type: types.BCH_BALANCE,
    payload: {
      bchBalance: bchBalance,
    },
  };
};

const fxtBalance = (fxtBalance) => {
  return {
    type: types.FXT_BALANCE,
    payload: {
      fxtBalance: fxtBalance,
    },
  };
};

const submittedKyc = (submittedKycData) => {
  return {
    type: types.SUBMITTED_KYC_DATA,
    payload: {
      submittedKycData: submittedKycData,
    },
  };
};

const singleKycData = (singleKycData) => {
  return {
    type: types.SINGLE_USER_KYC,
    payload: {
      singleKycData: singleKycData,
    },
  };
};

const getKycCount = (kycCount) => {
  return {
    type: types.KYC_COUNT,
    payload: {
      kycCount: kycCount,
    },
  };
};

const getStatusofLogin = (loginData) => {
  return {
    type: types.SAVE_LOGIN_STATUS,
    payload: {
      loginData: loginData,
    },
  };
};

export function signInFn(data, history) {
  return (dispatch, getState) => {
    dispatch(startLoader());
    return userService
      .login(data)
      .then((res) => {
        dispatch(stopLoader());

        toast.success(res.data.message);
        let options = {
          "api-access-token": res.data.JwtToken,
        };
        if (res.data.JwtToken) {
          return userService.get2fastatusApi(options).then((data) => {
            if (data.data.data.is_google_2fa_active == 1) {
              dispatch(tempSignInDetails(res.data.JwtToken, res.config.data));
              window.location.href = `/admin/google-authentication`;
              toast.success("Google Authentication required");
            } else {
              dispatch(signInDetails(res.data.JwtToken, res.config.data));

              // window.location.href = `/auth/dashboard`;
            }
            dispatch(stopLoader());
          });
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message);
        dispatch(stopLoader());
      });
  };
}

const get2fastatusFn = (data) => {
  // return (dispatch, getState) => {
  let options = {
    "api-access-token": data,
  };
  // dispatch(startLoader());
  userService
    .get2fastatusApi(data, options)
    .then((res) => {
      // dispatch(get2fastatus(res.data.data))
      return res.data.data;
      // dispatch(stopLoader());
    })
    .catch((error) => {
      toast.error(error?.data?.message);
      // dispatch(stopLoader());
      return false;
    });
  // };
};

// verify 2fa

export function google2faAuthenticateFn(data) {
  return (dispatch, getState) => {
    let options = {
      "api-access-token": getState().auth.tempTokens,
    };

    dispatch(startLoader());
    return userService
      .google2faAuthenticateApi(data, options)
      .then((res) => {
        toast.success(res.data.message);
        dispatch(
          signInDetails(getState().auth.tempTokens, getState().auth.userinfo)
        );
        // window.location.href = `/auth/dashboard`;
        dispatch(stopLoader());
      })
      .catch((error) => {
        toast.error(error?.data?.message);
        dispatch(stopLoader());
      });
  };
}

// verify device

export function deviceAuthenticateFn(data) {
  // debugger;
  return (dispatch, getState) => {
    dispatch(startLoader());
    return userService
      .deviceAuthenticateApi(data)
      .then((res) => {
        toast.success(res.data.message);
        dispatch(deviceAuthenticate(getState()));
        dispatch(stopLoader());
      })
      .catch((error) => {
        toast.error(error?.data?.message);
        dispatch(stopLoader());
      });
  };
}

/* User Start */

export function deleteApprovedKycFn(userId, history) {
  return (dispatch, getState) => {
    let options = {
      "api-access-token": getState().auth.tokens,
    };
    dispatch(startLoader());
    return userService
      .deleteApprovedKycApi(userId, options)
      .then((res) => {
        dispatch(stopLoader());
      })
      .catch((error) => {
        toast.error(error?.data?.message);
        dispatch(stopLoader());
      });
  };
}

export function updateUserDetailsFn(data, history) {
  return (dispatch, getState) => {
    let options = {
      "api-access-token": getState().auth.tokens,
    };
    dispatch(startLoader());
    return userService
      .updateUserDetailsApi(data, options)
      .then((res) => {
        history.push("/auth/approvedKyc");
        /* dispatch(getKycCount(res.data.data.file)) */
        dispatch(stopLoader());
      })
      .catch((error) => {
        toast.error(error?.data?.message);
        dispatch(stopLoader());
      });
  };
}

export function getKycFileFn(data, history) {
  return (dispatch, getState) => {
    let options = {
      "api-access-token": getState().auth.tokens,
    };
    dispatch(startLoader());
    return userService
      .getKycFileApi(data, options)
      .then((res) => {
        //  history.push('/auth/submittedKyc')
        dispatch(getKycCount(res.data.data.file));
        dispatch(stopLoader());
      })
      .catch((error) => {
        toast.error(error?.data?.message);
        dispatch(stopLoader());
      });
  };
}

export function getKycCountFn(data, history) {
  return (dispatch, getState) => {
    let options = {
      "api-access-token": getState().auth.tokens,
    };
    dispatch(startLoader());
    return userService
      .getKycCountApi(data, options)
      .then((res) => {
        //  history.push('/auth/submittedKyc')
        dispatch(getKycCount(res.data.data.listing[0].totalRecords));
        dispatch(stopLoader());
      })
      .catch((error) => {
        toast.error(error?.data?.message);
        dispatch(stopLoader());
      });
  };
}

export function kycActionFn(data, history) {
  return (dispatch, getState) => {
    let options = {
      "api-access-token": getState().auth.tokens,
    };
    dispatch(startLoader());
    return userService
      .kycActionApi(data, options)
      .then((res) => {
        history.push("/auth/submittedKyc");
        //  dispatch(singleKycData(res.data.data))
        dispatch(stopLoader());
      })
      .catch((error) => {
        toast.error(error?.data?.message);
        dispatch(stopLoader());
      });
  };
}

export function singleKycDataFn(userId, history) {
  return (dispatch, getState) => {
    let options = {
      "api-access-token": getState().auth.tokens,
    };
    dispatch(startLoader());
    return userService
      .singleKycDataApi(userId, options)
      .then((res) => {
        dispatch(singleKycData(res.data.data));
        dispatch(stopLoader());
      })
      .catch((error) => {
        toast.error(error?.data?.message);
        dispatch(stopLoader());
      });
  };
}

export const getImage = (data) => {
  return (dispatch, getState) => {
    let state = getState();
    let options = {
      "api-access-token": getState().auth.tokens,
    };
    // dispatch(startLoader());
    return new Promise((resolve, reject) => {
      userService
        .getImage(data, options)
        .then((res) => {
          // dispatch(stopLoader());
          // toast.success(res.data.message);
          resolve(res);
        })
        .catch((ex) => {
          dispatch(stopLoader());
          reject(ex);
        });
    });
  };
};

export function submittedKycFn(data, history) {
  return (dispatch, getState) => {
    let options = {
      "api-access-token": getState().auth.tokens,
    };
    dispatch(startLoader());
    return userService
      .submittedKycApi(data, options)
      .then((res) => {
        dispatch(submittedKyc(res.data.data.listing));
        dispatch(getKycCount(res.data.data.totalRecords));
        dispatch(stopLoader());
        return res.data.data.listing;
      })
      .catch((error) => {
        toast.error(error?.data?.message);
        dispatch(stopLoader());
      });
  };
}

export function getCsvRecordFn(data, history) {
  return (dispatch, getState) => {
    let options = {
      "api-access-token": getState().auth.tokens,
    };
    // dispatch(startLoader());
    return userService
      .submittedKycApi(data, options)
      .then((res) => {
        // dispatch(stopLoader());

        return res.data.data.listing;
        // dispatch(getKycCount(res.data.data.totalRecords));
      })
      .catch((error) => {
        toast.error(error?.data?.message);
        dispatch(stopLoader());
      });
  };
}

export function addNotesFn(data, history) {
  return (dispatch, getState) => {
    let options = {
      "api-access-token": getState().auth.tokens,
    };
    dispatch(startLoader());
    return userService
      .addNotesApi(data, options)
      .then((res) => {
        dispatch(stopLoader());
      })
      .catch((error) => {
        toast.error(error?.data?.message);
        dispatch(stopLoader());
      });
  };
}

export function filterUsersFn(data, history) {
  return (dispatch, getState) => {
    let options = {
      "api-access-token": getState().auth.tokens,
    };
    dispatch(startLoader());
    return userService
      .filterUsersApi(data, options)
      .then((res) => {
        dispatch(
          usersList(res.data.data.listing, res?.data?.data?.totalRecords)
        );
        dispatch(stopLoader());
      })
      .catch((error) => {
        toast.error(error?.data?.message);
        dispatch(stopLoader());
      });
  };
}

export function filterUserCSVFn(data, history) {
  return (dispatch, getState) => {
    let options = {
      "api-access-token": getState().auth.tokens,
    };
    // dispatch(startLoader());
    return userService
      .filterUsersApi(data, options)
      .then((res) => {
        return res.data.data.listing;
        //dispatch(usersList(res.data.data.listing, res?.data?.data?.totalRecords))
        //dispatch(stopLoader());
      })
      .catch((error) => {
        toast.error(error?.data?.message);
        // dispatch(stopLoader());
      });
  };
}

export function updateUsersLevel(data) {
  return (dispatch, getState) => {
    let options = {
      "api-access-token": getState().auth.tokens,
    };
    dispatch(startLoader());
    return userService
      .updateUsersLevel(data, options)
      .then((res) => {
        // dispatch(usersList(res.data.data.listing, res?.data?.data?.totalRecords))
        dispatch(stopLoader());
      })
      .catch((error) => {
        toast.error(error?.data?.message);
        dispatch(stopLoader());
      });
  };
}

export function userBalanceFn(data) {
  return (dispatch, getState) => {
    let options = {
      "api-access-token": getState().auth.tokens,
    };
    let balanceData = {
      coin: data.currency,
      user_id: data.user_id,
    };
    return userService
      .userBalanceApi(balanceData.user_id, options)
      .then((res) => {
        dispatch(getuserBalance(res.data.data));
        dispatch(stopLoader());
      })
      .catch((error) => {
        toast.error(error?.data?.message);
        dispatch(stopLoader());
      });
  };
}

export function statisticsFn(data, history) {
  return (dispatch, getState) => {
    let options = {
      "api-access-token": getState().auth.tokens,
    };
    dispatch(startLoader());
    return userService

      .statisticsApi(data, options)
      .then((res) => {
        toast.success(res.data?.error);
        dispatch(stopLoader());
      })
      .catch((error) => {
        toast.error(error?.data?.message);
        dispatch(stopLoader());
      });
  };
}

export function downlineFn(data, history) {
  return (dispatch, getState) => {
    let options = {
      "api-access-token": getState().auth.tokens,
    };
    dispatch(startLoader());
    return userService

      .downlineApi(data, options)
      .then((res) => {
        toast.success(res.data?.error);
        dispatch(stopLoader());
      })
      .catch((error) => {
        toast.error(error?.data?.message);
        dispatch(stopLoader());
      });
  };
}

export function singleTraderInfoFn(data, history) {
  return (dispatch, getState) => {
    let options = {
      "api-access-token": getState().auth.tokens,
    };
    dispatch(startLoader());
    return userService

      .singleTraderInfoApi(data, options)
      .then((res) => {
        dispatch(singleTraderData(res.data.data));
        dispatch(stopLoader());
      })
      .catch((error) => {
        toast.error(error?.data?.message);
        dispatch(stopLoader());
      });
  };
}

export function usersWithdrawTransFn(data) {
  return (dispatch, getState) => {
    let options = {
      "api-access-token": getState().auth.tokens,
    };
    dispatch(startLoader());
    return userService

      .usersWithdrawTransApi(data, options)
      .then((res) => {
        toast.success(res.data?.error);
        dispatch(usersWithdrawData(res.data.data));
        dispatch(stopLoader());
      })
      .catch((error) => {
        toast.error(error?.data?.message);
        dispatch(stopLoader());
      });
  };
}

export function sendAuthKeyFn(data, history) {
  return (dispatch, getState) => {
    let options = {
      "api-access-token": getState().auth.tokens,
    };
    dispatch(startLoader());
    return userService

      .sendAuthKeyApi(data, options)
      .then((res) => {
        toast.success(res.data?.error);
        dispatch(stopLoader());
      })
      .catch((error) => {
        toast.error(error?.data?.message);
        dispatch(stopLoader());
      });
  };
}

export function activateUser(data, history) {
  return (dispatch, getState) => {
    let options = {
      "api-access-token": getState().auth.tokens,
    };
    dispatch(startLoader());
    return userService

      .activateUserApi(data, options)
      .then((res) => {
        toast.success(res.data?.error);
        dispatch(stopLoader());
      })
      .catch((error) => {
        toast.error(error?.data?.message);
        dispatch(stopLoader());
      });
  };
}

export function userActionFn(data, history) {
  return (dispatch, getState) => {
    let options = {
      "api-access-token": getState().auth.tokens,
    };
    dispatch(startLoader());
    return userService

      .userActionApi(data, options)
      .then((res) => {
        dispatch(stopLoader());
      })
      .catch((error) => {
        toast.error(error?.data?.message);
        dispatch(stopLoader());
      });
  };
}

export function getUsersFn(data, history) {
  return (dispatch, getState) => {
    let options = {
      "api-access-token": getState().auth.tokens,
    };
    dispatch(startLoader());
    return userService

      .getUsersApi(data, options)
      .then((res) => {
        dispatch(
          usersList(res.data.data.listing, res?.data?.data?.totalRecords)
        );
        dispatch(stopLoader());
      })
      .catch((error) => {
        toast.error(error?.data?.message);
        dispatch(stopLoader());
      });
  };
}

export function getUsersCSVFn(data, history) {
  return (dispatch, getState) => {
    let options = {
      "api-access-token": getState().auth.tokens,
    };
    // dispatch(startLoader());
    return (
      userService
        // call same path as call in table
        // .getUsersApi(data, options)
        .filterUsersApi(data, options)
        .then((res) => {
          return res.data.data.listing;
          // dispatch(usersList(res.data.data.listing, res?.data?.data?.totalRecords));
          // dispatch(stopLoader());
        })
        .catch((error) => {
          //toast.error(error?.data?.message);
          //dispatch(stopLoader());
        })
    );
  };
}

export function getActiveUsersFn(history) {
  return (dispatch, getState) => {
    let options = {
      "api-access-token": getState().auth.tokens,
    };
    dispatch(startLoader());
    return userService
      .getActiveUsersApi(options)
      .then((res) => {
        dispatch(activeUsers(res.data?.data[0]?.activeUser));
        dispatch(stopLoader());
      })
      .catch((error) => {
        toast.error(error?.data?.message);
        dispatch(stopLoader());
      });
  };
}

export function checkIPTokenFn(data) {
  return (dispatch, getState) => {
    let options = {
      "api-access-token": getState().auth.tokens,
    };
    dispatch(startLoader());
    return userService
      .checkIpToken(data, options)
      .then((res) => {
        dispatch(getStatusofLogin(res.data?.data));
        dispatch(stopLoader());
      })
      .catch((error) => {
        toast.error(error?.data?.message);
        dispatch(stopLoader());
      });
  };
}

/* User End */

export function logoutUser(data, history) {
  return (dispatch, getState) => {
    if (data == "401") {
    } else {
      toast.success("Logout successfully");
    }
    dispatch(startLoader());
    dispatch(logout());
    localStorage.clear();
    dispatch(stopLoader());
  };
}
