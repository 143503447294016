import React from "react";
// import { Container, Row, Col } from "shards-react";
import {
  Badge,
  Button,
  Card,
  Col,
  Container,
  Dropdown,
  DropdownButton,
  Form,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { Icon, Pagination } from "semantic-ui-react";
// import PageTitle from "../../common/PageTitle";
import dateFormat from "dateformat";
import { CSVLink } from "react-csv";

import DatePicker from "react-datepicker";
import {
  activateUser,
  filterUserCSVFn,
  filterUsersFn,
  getActiveUsersFn,
  getUsersCSVFn,
  getUsersFn,
  sendAuthKeyFn,
  updateUsersLevel,
  userActionFn,
} from "../../../Redux/Actions/user/user.action";
// import "react-datepicker/dist/react-datepicker.css";
import "moment/locale/en-gb";
import { connect } from "react-redux";
// import "rc-datepicker/lib/style.css";
import "../../../App.css";
import { limit, offset } from "../../../Constants/constant";
import "./Users.css";
// import { NavLink as RouteNavLink } from "react-router-dom";
// import { NavLink } from "shards-react";
import { convertTZ } from "./../../common/helper";
class Users extends React.Component {
  constructor() {
    super();
    this.state = {
      disabled: false,
      stateCsvData: [],
      CSVDataList: [],
      totalDataCount: 0,
      page: 1,
      email: "",
      firstName: "",
      lastName: "",
      country: "",
      fromDate: "",
      toDate: "",
      userStatus: "",
      kycStatus: "",
      isEmailVerified: "",
      show: false,
      userLevel: "0",
      userId: "",
      limit: limit, //default PAGE_SIZE
      // add is loading csv
      isLoadingCSV: false,
      isDateRangeInvalid: false,
    };
    this.csvInstance = React.createRef();
  }

  getCSVList = () => {
    let statementCsvData = [];
    if (this.state.CSVDataList && this.state.CSVDataList.length > 0) {
      this.state.CSVDataList.map((stateData) => {
        let data = {
          Date: dateFormat(stateData?.created_at, "dd/mm/yyyy"),
          Time: dateFormat(
            convertTZ(stateData?.created_at, "Asia/Vientiane"),
            "h:MM:ss TT"
          ),
          Name:
            stateData.user_firstname != null
              ? stateData?.user_firstname + " " + stateData?.user_lastname
              : "N/A",
          email: stateData.email != null ? stateData.email : "N/A",
          "User Level":
            stateData.user_level == 0
              ? "Normal"
              : stateData.user_level == 1
              ? "VIP"
              : "--",
        };

        statementCsvData.push(data);
      });
      this.setState({ stateCsvData: statementCsvData });
    }
    this.setState({
      stateCsvData: statementCsvData,
    });
  };

  componentDidMount = () => {
    this.searchValues();
  };

  // export as csv function
  getUserCSVData = () => {
    this.setState({ CSVDataList: "", isLoadingCSV: true });

    let filterParams = {
      email: this.state.email,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      fromDate: this.state.fromDate
        ? dateFormat(this.state.fromDate, "yyyy-mm-dd")
        : "",
      offset: offset,
      page: 1,
      toDate: this.state.toDate
        ? dateFormat(this.state.toDate, "yyyy-mm-dd 23:59:59")
        : "",
      limit: this.props.totalRecords,
      ...(this.state.userStatus
        ? { userStatus: Number(this.state.userStatus) }
        : {}),
      ...(this.state.kycStatus
        ? { kycStatus: Number(this.state.kycStatus) }
        : {}),
      ...(this.state.isEmailVerified
        ? { isEmailVerified: Number(this.state.isEmailVerified) }
        : {}),
    };
    this.props
      .getUsersCSVFn(filterParams)
      .then((res) => {
        const formattedData = res.map((userItem, index) => ({
          "#": index + 1,
          Date: dateFormat(
            convertTZ(userItem?.created_at, "Asia/Vientiane"),
            "dd/mm/yyyy h:MM:ss TT"
          ),
          Name: userItem.user_firstname
            ? `${userItem?.user_firstname} ${userItem?.user_lastname}`
            : "N/A",
          email: userItem.email || "N/A",
          "Lock Status": this.userAccountStatus(userItem.user_status, true),
          "KYC Status": this.kycStatus(userItem.kycStatus, true),
          "Email Verified": userItem.isEmailVerified
            ? "Verified"
            : "Unverified",
        }));

        this.setState({ stateCsvData: formattedData, isLoadingCSV: false });
      })
      .catch(() => {
        this.setState({ isLoadingCSV: false });
      });
  };
  // trigger csv download after fetched data
  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.stateCsvData.length &&
      this.csvInstance.current &&
      this.csvInstance.current.link
    ) {
      setTimeout(() => {
        this.csvInstance.current.link.click();
        this.setState({ stateCsvData: [], isLoadingCSV: false });
      });
    }
  }

  handleSearch = () => {
    // set date range valid
    const { fromDate, toDate } = this.state;
    if ((fromDate && toDate) || (!fromDate && !toDate)) {
      this.setState(
        { ...this.state, page: 1, isDateRangeInvalid: false },
        () => {
          this.searchValues();
        }
      );
      return;
    }
    this.setState({ ...this.state, isDateRangeInvalid: true });
  };

  clearSearch = () => {
    this.setState(
      {
        email: "",
        country: "",
        fromDate: "",
        toDate: "",
        userStatus: "",
        kycStatus: "",
        isEmailVerified: "",
        isDateRangeInvalid: false,
        page: 1,
      },
      () => this.searchValues()
    );
  };

  pageChange = (e, data) => {
    let page = data.activePage;
    this.state.page = page;
    this.searchValues();
  };

  searchValues = () => {
    this.state.CSVDataList = "";
    let { filterUsersFn } = this.props;

    let searchedData = {
      country: this.state.country,
      email: this.state.email,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      fromDate: this.state.fromDate
        ? dateFormat(this.state.fromDate, "yyyy-mm-dd")
        : "",
      offset: offset,
      page: this.state.page,
      toDate: this.state.toDate
        ? dateFormat(this.state.toDate, "yyyy-mm-dd 23:59:59")
        : "",
      limit: this.state.limit,
      ...(this.state.userStatus
        ? { userStatus: Number(this.state.userStatus) }
        : {}),
      ...(this.state.kycStatus
        ? { kycStatus: Number(this.state.kycStatus) }
        : {}),
      ...(this.state.isEmailVerified
        ? { isEmailVerified: Number(this.state.isEmailVerified) }
        : {}),
    };
    filterUsersFn(searchedData);
  };

  getEmail = (e) => {
    this.setState({ email: e.target.value });
  };
  getFirstName = (e) => {
    this.setState({ firstName: e.target.value });
  };
  getLastName = (e) => {
    this.setState({ lastName: e.target.value });
  };

  getCountry = (e) => {
    this.setState({ country: e.target.value });
  };

  userAction = (action, id) => {
    let { userActionFn } = this.props;
    let data = {
      action: action,
      id: id,
    };
    userActionFn(data).then(() => {
      this.searchValues();
    });
  };

  sendAuthKey = (id) => {
    let { sendAuthKeyFn } = this.props;

    sendAuthKeyFn(id).then(() => {
      this.searchValues();
    });
  };

  activateUser = (emailid) => {
    let { activateUser } = this.props;

    let data = {
      email: emailid,
    };
    activateUser(data).then((res) => {
      this.searchValues();
    });
  };

  handleClose = () => {
    this.setState({
      show: false,
    });
  };

  saveUserLevel = () => {
    let userLevelData = {
      userId: this.state.userId,
      user_level: this.state.userLevel,
    };
    this.props.updateUsersLevel(userLevelData).then((res) => {
      this.handleClose();
      this.searchValues();
    });
  };

  // on change page size
  handleChangePageSize = (pageSize) => {
    this.setState({ limit: pageSize }, () => {
      // call back to load new user list with filter state
      this.searchValues();
    });
  };

  kycStatus = (statusNumber, isPlanText = false) => {
    switch (statusNumber) {
      case 0:
        return isPlanText ? (
          "Submitted"
        ) : (
          <Badge variant="info">Submitted</Badge>
        );
      case 1:
        return isPlanText ? (
          "Approved"
        ) : (
          <Badge variant="success">Approved</Badge>
        );
      case 2:
        return isPlanText ? (
          "Declined"
        ) : (
          <Badge variant="danger">Declined</Badge>
        );
      case 3:
        return isPlanText ? (
          "Re-submitted"
        ) : (
          <Badge variant="warning">Re-submitted</Badge>
        );
      default:
        return "--";
    }
  };

  userAccountStatus = (statusNumber, isPlanText = false) => {
    switch (statusNumber) {
      case 0:
        return isPlanText ? (
          "Inactive"
        ) : (
          <Badge variant="danger">Inactive</Badge>
        );
      case 1:
        return isPlanText ? "Active" : <Badge variant="success">Active</Badge>;
      case 2:
        return isPlanText ? (
          "Suspend"
        ) : (
          <Badge variant="warning">Suspend</Badge>
        );
      case 3:
        return isPlanText ? "Block" : <Badge variant="danger">Block</Badge>;
      default:
        return "--";
    }
  };
  render() {
    let { usersList, totalRecords } = this.props;
    let { toDate } = this.state;
    return (
      <div>
        <Container fluid className={"py-4"}>
          {/* Page Header */}

          <Card>
            <Card.Body>
              <Row className="page-header pb-3 justify-content-space-between">
                <Col>
                  <h3 className="page-title">{"Traders"}</h3>
                </Col>
                <Col className={"d-flex justify-content-end"}>
                  {/* react csv */}
                  <>
                    <div onClick={this.getUserCSVData}>
                      <Button
                        variant="white"
                        disabled={this.state.isLoadingCSV}
                      >
                        <Icon name="download" />
                        {this.state.isLoadingCSV
                          ? "Loading..."
                          : "Export as CSV"}
                      </Button>
                    </div>
                    {this.state.stateCsvData.length ? (
                      <CSVLink
                        data={this.state.stateCsvData}
                        filename={`Traders_${new Date().toISOString()}.csv`}
                        ref={this.csvInstance}
                      />
                    ) : undefined}
                  </>
                </Col>
              </Row>
              <Row>
                <Col lg={3} md={4} xs={12}>
                  <Form.Group>
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="search"
                      name="email"
                      id="email"
                      value={this.state.email}
                      placeholder="Email"
                      onChange={(e) => this.getEmail(e)}
                    />
                  </Form.Group>
                </Col>
                <Col lg={3} md={4} xs={12}>
                  <Form.Group>
                    <Form.Label>First Name</Form.Label>
                    <Form.Control
                      type="search"
                      name="frstName"
                      id="frstName"
                      value={this.state.firstName}
                      placeholder="FirstName"
                      onChange={(e) => this.getFirstName(e)}
                    />
                  </Form.Group>
                </Col>
                <Col lg={3} md={4} xs={12}>
                  <Form.Group>
                    <Form.Label>Last Name</Form.Label>

                    <Form.Control
                      type="search"
                      name="lastName"
                      id="lastName"
                      value={this.state.lastName}
                      placeholder="LastName"
                      onChange={(e) => this.getLastName(e)}
                    />
                  </Form.Group>
                </Col>
                <Col lg={3} md={4} xs={12}>
                  <Form.Group>
                    <Form.Label>Lock Status</Form.Label>
                    <Form.Control
                      as="select"
                      defaultValue={"Select Status"}
                      onChange={(e) =>
                        this.setState({ userStatus: e.target.value })
                      }
                    >
                      <option disabled>Select Status</option>
                      <option value={0}>Inactive</option>
                      <option value={1}>Active</option>
                      <option value={2}>Suspend</option>
                      <option value={3}>Block</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col lg={3} md={4} xs={12}>
                  <Form.Group>
                    <Form.Label>KYC Status</Form.Label>
                    <Form.Control
                      as="select"
                      defaultValue={"Select Status"}
                      onChange={(e) =>
                        this.setState({ kycStatus: e.target.value })
                      }
                    >
                      <option disabled>Select Status</option>
                      <option value={0}>Submitted</option>
                      <option value={1}>Approved</option>
                      <option value={2}>Declined</option>
                      <option value={3}>Re-Submitted</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col lg={3} md={4} xs={12}>
                  <Form.Group>
                    <Form.Label>Email Verify</Form.Label>
                    <Form.Control
                      as="select"
                      defaultValue={"Select Status"}
                      onChange={(e) =>
                        this.setState({ isEmailVerified: e.target.value })
                      }
                    >
                      <option disabled>Select Status</option>
                      <option value={0}>Unverified</option>
                      <option value={1}>Verified</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col lg={3} md={4} xs={12}>
                  <Form.Group>
                    <Form.Label>From Date</Form.Label>
                    <DatePicker
                      width="100%"
                      dateFormat="dd-MM-yyyy"
                      className="form-control"
                      selected={this.state.fromDate ? this.state.fromDate : ""}
                      placeholderText="DD/MM/YYYY"
                      onChange={(e) => this.setState({ fromDate: e })}
                    />
                    <Form.Text type="danger" muted>
                      {this.state.isDateRangeInvalid && !this.state.fromDate ? (
                        <span style={{ color: "red" }}>
                          Please select from date
                        </span>
                      ) : (
                        ""
                      )}
                    </Form.Text>
                  </Form.Group>
                </Col>
                <Col lg={3} md={4} xs={12}>
                  <Form.Label>To Date</Form.Label>

                  <DatePicker
                    disabled={!this.state?.fromDate}
                    style={{ width: "100%" }}
                    dateFormat="dd-MM-yyyy"
                    className="form-control d-block"
                    selected={toDate ? toDate : ""}
                    placeholderText="DD/MM/YYYY"
                    onChange={(e) => this.setState({ toDate: e })}
                  />
                  <Form.Text muted>
                    {this.state.isDateRangeInvalid && !this.state.toDate ? (
                      <span style={{ color: "red" }}>
                        Please select to date
                      </span>
                    ) : (
                      ""
                    )}
                  </Form.Text>
                </Col>
              </Row>
              <Row>
                <Col
                // className={"d-flex justify-content-center mt-2"}
                >
                  <Button
                    variant="primary"
                    // type="SUBMIT"
                    disabled={this.state.disabled}
                    onClick={this.handleSearch}
                  >
                    Search
                  </Button>
                  <Button
                    variant="danger"
                    className="ml-2"
                    onClick={this.clearSearch}
                  >
                    Reset
                  </Button>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <Card className="mt-4">
            <Card.Body>
              {/* <Row className={"justify-content-end"}>
                <Col className="d-flex justify-content-end">
                  <ImportCsv
                    stateCsvData={this.state.stateCsvData}
                    file_name={"Traders"}
                  />
                </Col>
              </Row> */}
              {/* <Row className="mt-4 bg-white"> */}
              {/* {usersList != undefined && usersList.length > 0 ? ( */}
              <Table striped bordered hover responsive size="sm">
                <thead>
                  <tr
                    style={{
                      height: "3rem",
                      color: "#3d5170",
                    }}
                  >
                    <th className="align-middle">#</th>
                    <th className="align-middle">Email Address</th>
                    <th className="align-middle">Full Name</th>
                    {/* <th className="align-middle">Last Name</th> */}
                    <th className="align-middle">Lock Status</th>
                    <th className="align-middle">KYC Status</th>
                    <th className="align-middle">Email Verify</th>
                    <th className="align-middle">Created At</th>
                    <th className="text-center align-middle">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {usersList.length > 0 ? (
                    usersList.map((userData, key) => {
                      return (
                        <tr key={`tr${key}`}>
                          <td className="text-center align-middle">
                            {(this.state.page - 1) * this.state.limit +
                              (key + 1)}
                          </td>
                          <td className="align-middle">
                            <Link to={`/auth/trader-view/${userData.users_id}`}>
                              {userData.email ? userData.email : "N/A"}
                            </Link>
                          </td>
                          {/* <td className="align-middle">
                            {userData.user_firstname ? (
                              // <Link
                              //   to={`/auth/trader-view/${userData.users_id}`}
                              // >
                                {userData.user_firstname}
                              // </Link>
                            ) : (
                              "N/A"
                            )}
                          </td> */}
                          <td className="align-middle">
                            {userData.user_firstname
                              ? `${userData.user_firstname} ${userData.user_lastname}`
                              : "N/A"}
                          </td>
                          {/* <td
                            onClick={() =>
                              this.setState({
                                show: true,
                                userId: userData.users_id,
                              })
                            }
                          >
                            {userData.user_level == 0
                              ? "Normal"
                              : userData.user_level == 1
                              ? "VIP"
                              : "--"}{" "}
                            <i class="fas fa-edit userlevelediticon"></i>
                          </td> */}
                          <td align="center" className="align-middle">
                            {this.userAccountStatus(userData.user_status)}
                          </td>
                          <td align="center" className="align-middle">
                            {this.kycStatus(userData.kycStatus) || "--"}
                          </td>
                          <td align="center" className="align-middle">
                            <Badge
                              variant={
                                userData.isEmailVerified ? "success" : "danger"
                              }
                            >
                              {userData.isEmailVerified
                                ? `Verified`
                                : `Unverified`}
                            </Badge>
                          </td>
                          <td className="align-middle">
                            {dateFormat(
                              convertTZ(userData?.created_at, "Asia/Vientiane"),
                              "dd/mm/yyyy h:MM:ss TT"
                            )}
                          </td>

                          <td className="d-flex align-items-center justify-content-center">
                            <DropdownButton
                              id="dropdown-item-button"
                              key={`action-dropdown-${userData.users_id}`}
                              drop="up"
                              size="sm"
                              title={
                                <Icon
                                  className="m-0"
                                  name="ellipsis vertical"
                                />
                              }
                              style={{ paddingRight: "0", cursor: "pointer" }}
                              variant="outline-secondary"
                            >
                              {/* <Dropdown.ItemText>
                                Dropdown item text
                              </Dropdown.ItemText> */}
                              {/* <Dropdown.Item as="button">
                                <Link
                                  to={`/auth/trader-view/${userData.users_id}`}
                                  style={{
                                    textDecoration: "none",
                                    color: "inherit",
                                  }}
                                >
                                  <Icon name="eye" />
                                  View
                                </Link>
                              </Dropdown.Item> */}
                              <Dropdown.Item as="button">
                                <Link
                                  to={`/auth/kycDetail/${userData?.users_id}`}
                                  style={{
                                    textDecoration: "none",
                                    color: "inherit",
                                  }}
                                >
                                  <Icon name="address card outline" />
                                  KYC
                                </Link>
                              </Dropdown.Item>
                              <Dropdown.Item
                                as="button"
                                onClick={() =>
                                  this.userAction(
                                    userData.user_status === 2
                                      ? "unlock"
                                      : "suspend",
                                    userData.users_id
                                  )
                                }
                              >
                                {userData.user_status === 2 ? (
                                  <>
                                    <Icon
                                      name="unlock alternate"
                                      style={{ color: "#17c671" }}
                                    />
                                    Unlock
                                  </>
                                ) : (
                                  <>
                                    <Icon
                                      name="lock"
                                      style={{ color: "#dc3545" }}
                                    />
                                    Lock
                                  </>
                                )}
                              </Dropdown.Item>
                              <Dropdown.Item
                                as="button"
                                onClick={() =>
                                  this.sendAuthKey(userData.users_id)
                                }
                                disabled={
                                  userData.google_auth_active == 0
                                    ? true
                                    : false
                                }
                              >
                                <Icon name="key" />
                                2FA
                              </Dropdown.Item>
                              {/* {userData.retry_count == 5 && ( */}
                              <Dropdown.Item
                                as="button"
                                onClick={() =>
                                  this.activateUser(userData.email)
                                }
                                disabled={
                                  userData.retry_count >= 5 ? true : true
                                }
                              >
                                <Icon name="magic" />
                                Activate
                              </Dropdown.Item>
                              {/* )} */}
                            </DropdownButton>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr style={{ height: "8rem" }}>
                      <td colSpan="8" className="text-center align-middle">
                        No Record Found
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
              <Row className="justify-content-space-between">
                <Col>
                  <Dropdown>
                    <Dropdown.Toggle
                      size="sm"
                      variant="outline-secondary"
                      id="pagesize-dropdown"
                    >
                      <Icon name="caret up" />
                      {`Show ${this.state.limit} items/page`}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() => this.handleChangePageSize(50)}
                      >
                        Show 50 items
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => this.handleChangePageSize(100)}
                      >
                        Show 100 items
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => this.handleChangePageSize(150)}
                      >
                        Show 150 items
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => this.handleChangePageSize(250)}
                      >
                        Show 250 items
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => this.handleChangePageSize(500)}
                      >
                        Show 500 items
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
                <Col className={"d-flex justify-content-end"}>
                  <Pagination
                    size="mini"
                    activePage={this.state.page}
                    onPageChange={this.pageChange}
                    ellipsisItem={{
                      content: <Icon name="ellipsis horizontal" />,
                      icon: true,
                    }}
                    firstItem={{
                      content: <Icon name="angle double left" />,
                      icon: true,
                    }}
                    lastItem={{
                      content: <Icon name="angle double right" />,
                      icon: true,
                    }}
                    prevItem={{
                      content: <Icon name="angle left" />,
                      icon: true,
                    }}
                    nextItem={{
                      content: <Icon name="angle right" />,
                      icon: true,
                    }}
                    totalPages={Math.ceil(totalRecords / this.state.limit)}
                  />
                </Col>
              </Row>

              <Modal
                show={this.state.show}
                onHide={this.handleClose}
                keyboard={false}
              >
                <Modal.Header>
                  <Modal.Title>Please select user level</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div>
                    <select
                      className="form-control"
                      onChange={(e) =>
                        this.setState({ userLevel: e.target.value })
                      }
                    >
                      <option value="0">Normal</option>
                      <option value="1">VIP</option>
                    </select>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={this.handleClose}>
                    Close
                  </Button>
                  <Button variant="primary" onClick={this.saveUserLevel}>
                    Submit
                  </Button>
                </Modal.Footer>
              </Modal>
              {/* </Row> */}
            </Card.Body>
          </Card>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    usersList: state.user.usersList,
    activeUsers: state.user.activeUsers,
    totalRecords: state.user.totalRecords,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUsersFn: (data) => dispatch(getUsersFn(data)),
    getActiveUsersFn: (data) => dispatch(getActiveUsersFn(data)),
    userActionFn: (data) => dispatch(userActionFn(data)),
    sendAuthKeyFn: (data) => dispatch(sendAuthKeyFn(data)),
    filterUsersFn: (data) => dispatch(filterUsersFn(data)),
    updateUsersLevel: (data) => dispatch(updateUsersLevel(data)),
    activateUser: (data) => dispatch(activateUser(data)),
    getUsersCSVFn: (data) => dispatch(getUsersCSVFn(data)),
    filterUserCSVFn: (data) => dispatch(filterUserCSVFn(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);
