import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import * as Yup from "yup";
import {
  editFee,
  editSwapPairFee,
  editSwapPairSellFee,
  getSwapPairData,
} from "../../../Redux/Actions/currency/currency.action";
import { toast } from "../../Toast/toast.component";

const EditSwapPair = (props) => {
  const swappairid = props.match.params.swappairid;
  const history = useHistory();

  const formik = useFormik({
    initialValues: {
      minOrder: "",
      maxOrder: "",
      sellMaxOrder: "",
      fee: "",
      sellFee: "",
      buy_price: "",
      sell_price: "",
      sellMinOrder: "",
      vip_max_order_buy: "",
      vip_max_order_sell: "",
      buy_user_level: "",
      sell_user_level: "",
    },
    validationSchema: Yup.object({
      minOrder: Yup.number().required("Min Order is Required"),
      maxOrder: Yup.number().required("Max Order is Required"),
      fee: Yup.number().required("Fee is Required"),
      sellFee: Yup.number().required("Fee is Required"),
      buyPrice: Yup.number().required("Buy Price is Required"),
      sellMinOrder: Yup.number().required("Min Order is Required"),
      sellMaxOrder: Yup.number().required("Mix Order is Required"),
      vip_max_order_buy: Yup.number().required("VIP Max Order is Required"),
      vip_max_order_sell: Yup.number().required("VIP Max Order is Required"),
      buy_user_level: Yup.object().required("Buy User level is Required"),
      sell_user_level: Yup.object().required("Sell User level is Required"),
    }),

    onSubmit: (values) => {
      //console.log(values, swappairid, "vvvvaaa");
      let swappairData = {
        id: swappairid,
        fee: values.fee,
        buy_price: values.buy_price,
        min_order: values.minOrder,
        max_order: values.maxOrder,
        vip_max_order_buy: values.vip_max_order_buy,
        buy_user_level: values.buy_user_level,
      };
      //   values.pairId = pairId;
      //   values.pair = pairCoin.toLowerCase();
      props.editSwapPairFee(swappairData, history);
    },
  });

  const updateSwapPair = (type) => {
    // console.log(formik.values)
    if (type === "buy") {
      if (formik.values.minOrder > formik.values.maxOrder) {
        toast.error("Minimum order should not greater then maxmimu order");
        return;
      }
      let swappairData = {
        id: swappairid,
        fee: formik.values.fee,
        buy_price: formik.values.buy_price,
        min_order: formik.values.minOrder,
        max_order: formik.values.maxOrder,
        vip_max_order_buy: formik.values.vip_max_order_buy,
        buy_user_level: formik.values.buy_user_level,
      };
      //   values.pairId = pairId;
      //   values.pair = pairCoin.toLowerCase();
      props.editSwapPairFee(swappairData, history).then((res) => {
        getSwapEditData();
        //history.push("/admin/auth/swappair");
        // window.location.reload();
      });
    } else {
      if (formik.values.sellMinOrder > formik.values.sellMaxOrder) {
        toast.error("Minimum order should not be greater then maxmimu order");
        return;
      }
      let swappairData = {
        id: swappairid,
        sell_fee: formik.values.sellFee,
        sell_min_order: formik.values.sellMinOrder,
        sell_max_order: formik.values.sellMaxOrder,
        sell_price: formik.values.sell_price,
        vip_max_order_sell: formik.values.vip_max_order_sell,
        sell_user_level: formik.values.sell_user_level,
      };
      //   values.pairId = pairId;
      //   values.pair = pairCoin.toLowerCase();
      props.editSwapPairSellFee(swappairData).then((res) => {
        getSwapEditData();
        // history.push("/admin/auth/swappair");
        // window.location.reload();
      });
    }
  };

  useEffect(() => {
    //console.log("calllledldld");
    // let values = queryString.parse(this.props.location.search);
    // let { getPairListFn } = this.props;
    // let searchedParams = { page: this.state.page };
    getSwapEditData();
    // getAllDepositesFn();
  }, []);

  useEffect(() => {
    let { editSwapPairData } = props;
    if (editSwapPairData != undefined) {
      let editData = editSwapPairData[0];
      formik.setFieldValue("minOrder", editData?.min_order);
      formik.setFieldValue("maxOrder", editData?.max_order);
      formik.setFieldValue("fee", editData?.fee);
      formik.setFieldValue("sellFee", editData?.sell_fee);
      formik.setFieldValue("buy_price", editData?.buy_price);
      formik.setFieldValue("sellMinOrder", editData?.sell_min_order);
      formik.setFieldValue("sellMaxOrder", editData?.sell_max_order);
      formik.setFieldValue("sell_price", editData?.sell_price);
      formik.setFieldValue("vip_max_order_buy", editData?.vip_max_order_buy);
      formik.setFieldValue(
        "normal_max_order_buy",
        editData?.normal_max_order_buy
      );
      formik.setFieldValue("vip_max_order_sell", editData?.vip_max_order_sell);
      formik.setFieldValue(
        "normal_max_order_sell",
        editData?.normal_max_order_sell
      );
      formik.setFieldValue("buy_user_level", editData?.buy_user_level);
      formik.setFieldValue("sell_user_level", editData?.sell_user_level);
      formik.setFieldValue("pair_name", editData?.pair_name);
    }
  }, [props.editSwapPairData]);

  const getSwapEditData = () => {
    let { getSwapPairData } = props;
    let data = {
      id: swappairid,
    };
    getSwapPairData(data);
    // if (props.editSwapPairData.length > 0) {
    //   console.log(props.editSwapPairData[0], "edit data");

    // setValue("minOrder", editData?.min_order);
    // setValue("maxOrder", editData?.max_order);
    // setValue("fee", editData?.fee);
    // setValue("buyPrice", editData?.buyPrice);
    // }
  };
  return (
    <Container fluid className={"py-4"}>
      <Card className="mb-4">
        <Card.Body>
          <Row className="page-header pb-3 justify-content-space-between">
            <Col>
              {/* <h3 className="page-title">{"Edit Crypto Fee"}</h3> */}
              <h3 className="page-title">{`${"Buy Crypto Pair"}`}</h3>
              <h6 className="mt-1 text-muted">{`${formik?.values?.pair_name?.replace(
                "_",
                "/"
              )}`}</h6>
            </Col>
          </Row>
          <Form onSubmit={formik.handleSubmit}>
            <Row>
              <Col xs={12} xl={6}>
                <Form.Group controlId="minOrder">
                  <Form.Label>Minimum Order</Form.Label>
                  {/* <label htmlFor="firstName">First Name</label> */}
                  <Form.Control
                    id="minOrder"
                    name="minOrder"
                    type="number"
                    placeholder="Minimum Order"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    //maxLength={9}
                    // pattern="[+-]?\d+(?:[.,]\d+)?"
                    value={formik.values.minOrder}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} xl={6}>
                <Form.Group controlId="maxOrder">
                  <Form.Label>Normal Maximum Order</Form.Label>
                  <Form.Control
                    id="maxOrder"
                    name="maxOrder"
                    placeholder="Maximum order"
                    type="number"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.maxOrder}
                  />
                </Form.Group>
              </Col>

              <Col xs={12} xl={6}>
                <Form.Group controlId="fee">
                  <Form.Label>Fee</Form.Label>
                  <Form.Control
                    id="fee"
                    name="fee"
                    placeholder="Fee"
                    type="number"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.fee}
                  />
                </Form.Group>
              </Col>

              <Col xs={12} xl={6}>
                <Form.Group controlId="buy_price">
                  <Form.Label>Buy Price</Form.Label>
                  <Form.Control
                    id="buy_price"
                    name="buy_price"
                    placeholder="Buy Price"
                    type="number"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.buy_price}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} xl={6}>
                <Form.Group controlId="vip_max_order_buy">
                  <Form.Label>VIP Max Order</Form.Label>
                  <Form.Control
                    id="vip_max_order_buy"
                    name="vip_max_order_buy"
                    placeholder="VIP Max Order"
                    type="number"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.vip_max_order_buy}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} xl={6}>
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>Select User Level</Form.Label>

                  <Form.Control
                    as={"select"}
                    style={{ width: "100%" }}
                    name="buy_user_level"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.buy_user_level}
                  >
                    <option value="0">Normal</option>
                    <option value="1">VIP</option>
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col className={"d-flex justify-content-center mt-2"}>
                <Button
                  variant="primary"
                  type="submit"
                  onClick={() => updateSwapPair("buy")}
                >
                  Update
                </Button>
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>

      <Card>
        <Card.Body>
          <Row className="page-header pb-3 justify-content-space-between">
            <Col>
              {/* <h3 className="page-title">{"Edit Crypto Fee"}</h3> */}
              <h3 className="page-title">{`${"Sell Crypto Pair"}`}</h3>
              <h6 className="mt-1 text-muted">{`${formik?.values?.pair_name?.replace(
                "_",
                "/"
              )}`}</h6>
            </Col>
          </Row>
          <Form onSubmit={formik.handleSubmit}>
            <Row>
              <Col xs={12} xl={6}>
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>Minimum Order</Form.Label>
                  <Form.Control
                    id="sellMinOrder"
                    name="sellMinOrder"
                    type="number"
                    placeholder="Minimum Order"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    //maxLength={9}
                    // pattern="[+-]?\d+(?:[.,]\d+)?"
                    value={formik.values.sellMinOrder}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} xl={6}>
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>Normal Maximum Order</Form.Label>
                  <Form.Control
                    id="sellMaxOrder"
                    name="sellMaxOrder"
                    placeholder="Maximum order"
                    type="number"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.sellMaxOrder}
                  />
                </Form.Group>
              </Col>

              <Col xs={12} xl={6}>
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>Fee</Form.Label>
                  <Form.Control
                    id="sellFee"
                    name="sellFee"
                    placeholder="Fee"
                    type="number"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.sellFee}
                  />
                </Form.Group>
              </Col>

              <Col xs={12} xl={6}>
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>Sell Price</Form.Label>
                  <Form.Control
                    id="sell_price"
                    name="sell_price"
                    placeholder="Buy Price"
                    type="number"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.sell_price}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} xl={6}>
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>VIP Max Order</Form.Label>
                  <Form.Control
                    id="vip_max_order_sell"
                    name="vip_max_order_sell"
                    placeholder="VIP Max Order"
                    type="number"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.vip_max_order_sell}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} xl={6}>
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>Select User Level</Form.Label>

                  <Form.Control
                    as={"select"}
                    style={{ width: "100%" }}
                    name="sell_user_level"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.sell_user_level}
                  >
                    <option value="">All</option>
                    <option value="0">Normal</option>
                    <option value="1">VIP</option>
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col className={"d-flex justify-content-center mt-2"}>
                <Button
                  variant="primary"
                  type="submit"
                  onClick={() => updateSwapPair("sell")}
                >
                  Update
                </Button>
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
};
const mapStateToProps = (state) => {
  return {
    editSwapPairData: state.currency.editSwapPairData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleEditUserForm: (data, history) => dispatch(editFee(data, history)),
    editSwapPairFee: (data, history) =>
      dispatch(editSwapPairFee(data, history)),
    editSwapPairSellFee: (data) => dispatch(editSwapPairSellFee(data)),
    getSwapPairData: (data) => dispatch(getSwapPairData(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(EditSwapPair);
