import "rc-datepicker/lib/style.css";
import React from "react";
import {
  Badge,
  Button,
  Card,
  Col,
  Container,
  Dropdown,
  Form,
  Row,
  Table,
} from "react-bootstrap";
import { CSVLink } from "react-csv";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";
import { Icon, Pagination } from "semantic-ui-react";

import {
  filterUsersFn,
  getActiveUsersFn,
  getCsvRecordFn,
  getKycCountFn,
  getUsersFn,
  sendAuthKeyFn,
  submittedKycFn,
  userActionFn,
} from "../../../Redux/Actions/user/user.action";

import dateFormat from "dateformat";
import moment from "moment";
import "moment/locale/en-gb";
import { connect } from "react-redux";
import "../../../App.css";
import { KYC_STATUS, limit } from "../../../Constants/constant";
import "../user/Users.css";
import { convertTZ } from "./../../common/helper";

class Users extends React.Component {
  constructor() {
    super();
    this.state = {
      disabled: true,
      stateCsvData: [],
      totalDataCount: 0,
      page: 1,
      currentpage: 1,
      filterData: {
        email: "",
        kyc_status: "",
        limit: limit,
        fromDate: "",
        toDate: "",
      },
      // add is loading csv
      isLoadingCSV: false,
      isDateRangeInvalid: false,
    };
    this.csvInstance = React.createRef();
  }

  getCsvRecord = () => {
    this.setState({ isLoadingCSV: true });

    let searchedParams = {
      page: 1,
      search: this.state.filterData.email,
      limit: this.props.totalRecords,
      status: this.state.filterData.kyc_status,
      // startDate: this.state.filterData.fromDate,
      startDate: this.state.filterData.fromDate
        ? dateFormat(this.state.filterData.fromDate, "yyyy-mm-dd")
        : "",
      // endDate: this.state.filterData.toDate,
      endDate: this.state.filterData.toDate
        ? dateFormat(this.state.filterData.toDate, "yyyy-mm-dd 23:59:59")
        : "",
    };

    //getUsersFn(searchedParams);
    this.props
      .getCsvRecordFn(searchedParams)
      .then((res) => {
        let formattedData = res.map((kycItem) => {
          return {
            UID: kycItem?.users_id,
            "First Name": kycItem?.firstname ? kycItem?.firstname : "N/A",
            "Last Name": kycItem?.lastname ? kycItem?.lastname : "N/A",
            Email: kycItem?.email != null ? kycItem?.email : "N/A",
            "Phone Number": kycItem?.mobile_no,
            City: kycItem?.city,
            Country: kycItem?.country_id,
            DOB: kycItem?.dob,
            Gender: kycItem?.gender ? "FAMALE" : "MALE",
            "Zip Code/Postal Code": kycItem?.zip,
            "Action By": kycItem?.adminFirstname
              ? kycItem?.adminFirstname + " " + kycItem?.adminlastname
              : "--",
            "Action Date":
              kycItem?.status == 1 && kycItem?.approved_at != null
                ? moment(kycItem?.approved_at).format("DD/MM/YYYY")
                : kycItem?.status == 2 && kycItem?.declined_at != null
                ? moment(kycItem?.declined_at).format("DD/MM/YYYY")
                : kycItem?.status == 3 && kycItem?.declined_at != null
                ? moment(kycItem?.declined_at).format("DD/MM/YYYY")
                : kycItem?.status == 0
                ? "--"
                : "--",

            "KYC Submitted Date": dateFormat(
              kycItem?.submitted_at,
              "dd/mm/yyyy"
            ),
            Time: dateFormat(
              convertTZ(kycItem?.submitted_at, "Asia/Vientiane"),
              "h:MM:ss TT"
            ),
            Status:
              kycItem?.status == 0
                ? "Submitted"
                : kycItem?.status == 1
                ? "Approved"
                : kycItem?.status == 2
                ? "declined"
                : kycItem?.status == 3 && "Re-submitted",
          };
        });
        this.setState({ stateCsvData: formattedData, isLoadingCSV: false });
      })
      .catch(() => {
        this.setState({ isLoadingCSV: false });
      });
  };
  // trigger csv download after fetched data
  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.stateCsvData.length &&
      this.csvInstance.current &&
      this.csvInstance.current.link
    ) {
      setTimeout(() => {
        this.csvInstance.current.link.click();
        this.setState({ stateCsvData: [], isLoadingCSV: false });
      });
    }
  }

  getCsvData = (dataVal) => {
    let submittedKycData = dataVal;
    let statementCsvData = [];
    if (submittedKycData !== undefined && submittedKycData.length > 0) {
      submittedKycData.map((stateData) => {
        let data = {
          UID: stateData?.users_id,
          "First Name": stateData?.firstname ? stateData?.firstname : "N/A",
          "Last Name": stateData?.lastname ? stateData?.lastname : "N/A",
          Email: stateData?.email != null ? stateData?.email : "N/A",
          "Phone Number": stateData?.mobile_no,
          City: stateData?.city,
          Country: stateData?.country_id,
          DOB: stateData?.dob,
          Gender: stateData?.gender ? "FAMALE" : "MALE",
          "Zip Code/Postal Code": stateData?.zip,
          "Action By": stateData?.adminFirstname
            ? stateData?.adminFirstname + " " + stateData?.adminlastname
            : "--",
          "Action Date":
            stateData?.status == 1 && stateData?.approved_at != null
              ? moment(stateData?.approved_at).format("DD/MM/YYYY")
              : stateData?.status == 2 && stateData?.declined_at != null
              ? moment(stateData?.declined_at).format("DD/MM/YYYY")
              : stateData?.status == 3 && stateData?.declined_at != null
              ? moment(stateData?.declined_at).format("DD/MM/YYYY")
              : stateData?.status == 0
              ? "--"
              : "--",

          "KYC Submitted Date": dateFormat(
            stateData?.submitted_at,
            "dd/mm/yyyy"
          ),
          Time: dateFormat(
            convertTZ(stateData?.submitted_at, "Asia/Vientiane"),
            "h:MM:ss TT"
          ),
          Status:
            stateData?.status == 0
              ? "Submitted"
              : stateData?.status == 1
              ? "Approved"
              : stateData?.status == 2
              ? "declined"
              : stateData?.status == 3 && "Re-submitted",
        };

        statementCsvData.push(data);
      });
      this.setState({ stateCsvData: statementCsvData });
    } else {
      this.setState({
        stateCsvData: "",
        // dataLength: nextProps.customerDataLength,
      });
    }
  };

  componentDidMount = () => {
    this.searchValues();

    // let { getActiveUsersFn, submittedKycFn, getKycCountFn } = this.props;

    // let searchedParams = {
    //   page: this.state.page,
    //   search: this.state.filterData.email,
    //   limit: this.state.filterData.limit,
    //   status: this.state.filterData.kyc_status,
    //   startDate: this.state.filterData.fromDate
    //     ? dateFormat(this.state.filterData.fromDate, "yyyy-mm-dd")
    //     : "",
    //   endDate: this.state.filterData.toDate
    //     ? dateFormat(this.state.filterData.toDate, "yyyy-mm-dd 23:59:59")
    //     : "",
    // };

    // //getUsersFn(searchedParams);
    // submittedKycFn(searchedParams).then((res) => {
    //   this.getCsvRecord();
    // });
    // getActiveUsersFn();
    // getKycCountFn({ status: "0,3" });
  };

  handleSearch = () => {
    // set date range valid
    const { fromDate, toDate } = this.state.filterData;
    if ((fromDate && toDate) || (!fromDate && !toDate)) {
      this.setState(
        { ...this.state, page: 1, isDateRangeInvalid: false },
        () => {
          this.searchValues();
        }
      );
      return;
    }
    this.setState({ ...this.state, isDateRangeInvalid: true });

    // this.setState({ ...this.state, page: 1 }, () => {
    //   this.searchValues();
    // });
  };

  clearSearch = () => {
    this.setState({
      filterData: {
        email: "",
        kyc_status: "",
        limit: limit,
        fromDate: "",
        toDate: "",
      },
      isDateRangeInvalid: false,
    });
    this.setState({ currentpage: 1 });
    let searchedParams = {
      page: 1,
      search: "",
      limit: limit,
      status: "",
      startDate: "",
      endDate: "",
    };
    //getUsersFn(searchedParams);
    this.props.submittedKycFn(searchedParams);
    // .then((res) => {
    //   this.getCsvRecord();
    // });
  };

  pageChange = (e, data) => {
    this.setState({ currentpage: data.activePage, page: data.activePage });
    this.searchValues();
    // let page = data.activePage;

    // let searchedParams = {
    //   page: `${page}`,
    //   search: this.state.filterData.email,
    //   limit: this.state.filterData.limit,
    //   status: this.state.filterData.kyc_status,
    //   startDate: this.state.filterData.fromDate
    //     ? dateFormat(this.state.filterData.fromDate, "yyyy-mm-dd")
    //     : "",
    //   endDate: this.state.filterData.toDate
    //     ? dateFormat(this.state.filterData.toDate, "yyyy-mm-dd 23:59:59")
    //     : "",
    // };
    // // this.props.getUsersFn(searchedParams);
    // this.props.submittedKycFn(searchedParams).then((res) => {
    //   this.getCsvRecord();
    // });
  };

  searchValues = () => {
    let { submittedKycFn } = this.props;

    let searchedData = {
      page: this.state.page,
      search: this.state.filterData.email,
      limit: this.state.filterData.limit,
      status: this.state.filterData.kyc_status,
      startDate: this.state.filterData.fromDate
        ? dateFormat(this.state.filterData.fromDate, "yyyy-mm-dd")
        : "",
      endDate: this.state.filterData.toDate
        ? dateFormat(this.state.filterData.toDate, "yyyy-mm-dd 23:59:59")
        : "",
    };
    submittedKycFn(searchedData);
  };

  getFilterInputData = (e, type) => {
    let { filterData } = this.state;
    if (type == "email") filterData.email = e.target.value;
    else if (type == "kyc_status") {
      filterData.kyc_status = e.target.value;
    } else if (type == "limit") {
      filterData.limit = e.target.value;
    } else if (type == "fromDate") {
      filterData.fromDate = e;
    } else if (type == "toDate") {
      filterData.toDate = e;
    }
    this.setState({ filterData: filterData });
  };

  // on change page size
  handleChangePageSize = (pageSize) => {
    this.setState(
      { filterData: { ...this.state.filterData, limit: pageSize } },
      () => {
        // call back to load new user list with filter state
        this.searchValues();
      }
    );
  };

  // find KYC status
  kycStatus = (statusNumber, isPlanText = false) => {
    switch (statusNumber) {
      case 0:
        return isPlanText ? (
          "Submitted"
        ) : (
          <Badge variant="info">Submitted</Badge>
        );
      case 1:
        return isPlanText ? (
          "Approved"
        ) : (
          <Badge variant="success">Approved</Badge>
        );
      case 2:
        return isPlanText ? (
          "Declined"
        ) : (
          <Badge variant="danger">Declined</Badge>
        );
      case 3:
        return isPlanText ? (
          "Re-submitted"
        ) : (
          <Badge variant="warning">Re-submitted</Badge>
        );
      default:
        return "--";
        break;
    }
  };
  render() {
    let { submittedKycData, kycCount } = this.props;
    let { filterData } = this.state;
    return (
      <div>
        <Container fluid className={"py-4"}>
          <Card>
            <Card.Body>
              <Row className="page-header pb-3 justify-content-space-between">
                <Col>
                  <h3 className="page-title">{"KYC"}</h3>
                </Col>
                <Col className={"d-flex justify-content-end"}>
                  {/* <ImportCsv
                    stateCsvData={this.state.stateCsvData}
                    file_name={"Submitted Kyc"}
                  /> */}
                  {/* react csv */}
                  <>
                    <div onClick={this.getCsvRecord}>
                      <Button
                        variant="white"
                        disabled={this.state.isLoadingCSV}
                      >
                        <Icon name="download" />
                        {this.state.isLoadingCSV
                          ? "Loading..."
                          : "Export as CSV"}
                      </Button>
                    </div>
                    {this.state.stateCsvData.length ? (
                      <CSVLink
                        data={this.state.stateCsvData}
                        filename={`Submitted_Kyc${new Date().toISOString()}.csv`}
                        ref={this.csvInstance}
                      />
                    ) : undefined}
                  </>
                </Col>
              </Row>
              <Row>
                <Col lg={3} md={4} xs={12}>
                  <Form.Group>
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="search"
                      name="search"
                      id="search"
                      value={filterData.email.trim()}
                      placeholder="SearchKYC Detail"
                      onChange={(e) => this.getFilterInputData(e, "email")}
                    />
                  </Form.Group>
                </Col>
                <Col lg={3} md={4} xs={12}>
                  <Form.Group>
                    <Form.Label>KYC Status</Form.Label>
                    <Form.Control
                      as="select"
                      defaultValue="Status"
                      name="kyc_status"
                      id="status"
                      value={filterData.kyc_status}
                      onChange={(e) => this.getFilterInputData(e, "kyc_status")}
                    >
                      {KYC_STATUS.map((status, key) => {
                        return (
                          <option key={`kyc-${key}`} value={status.value}>
                            {status.name}
                          </option>
                        );
                      })}
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col lg={3} md={4} xs={12}>
                  <Form.Group>
                    <Form.Label>From Date</Form.Label>

                    <DatePicker
                      dateFormat="dd-MM-yyyy"
                      selected={filterData.fromDate ? filterData.fromDate : ""}
                      placeholderText="From: DD/MM/YYYY"
                      className="form-control"
                      onChange={(e) => this.getFilterInputData(e, "fromDate")}
                    />
                    <Form.Text type="danger" muted>
                      {this.state.isDateRangeInvalid &&
                      !this.state.filterData.fromDate ? (
                        <span style={{ color: "red" }}>
                          Please select from date
                        </span>
                      ) : (
                        ""
                      )}
                    </Form.Text>
                  </Form.Group>
                </Col>
                <Col lg={3} md={4} xs={12}>
                  <Form.Group>
                    <Form.Label>To Date</Form.Label>
                    <DatePicker
                      disabled={!filterData?.fromDate}
                      minDate={new Date(filterData.fromDate)}
                      dateFormat="dd-MM-yyyy"
                      selected={filterData.toDate ? filterData.toDate : ""}
                      placeholderText="To: DD/MM/YYYY"
                      className="form-control"
                      onChange={(e) => this.getFilterInputData(e, "toDate")}
                    />
                    <Form.Text muted>
                      {this.state.isDateRangeInvalid &&
                      !this.state.filterData.toDate ? (
                        <span style={{ color: "red" }}>
                          Please select to date
                        </span>
                      ) : (
                        ""
                      )}
                    </Form.Text>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col
                // className={"d-flex justify-content-center mt-2"}
                >
                  <Button
                    variant="primary"
                    type="submit"
                    // disabled={this.state.disabled}
                    onClick={this.handleSearch}
                  >
                    Search
                  </Button>

                  <Button
                    variant="danger"
                    className="ml-2"
                    onClick={this.clearSearch}
                  >
                    Reset
                  </Button>
                </Col>
              </Row>
            </Card.Body>
          </Card>

          {/* <Row className="users_row">
            <Col>
              <Form.Group>
                <Form.Control
                  as="select"
                  defaultValue="Limit"
                  name="limit"
                  id="limit"
                  value={filterData.limit}
                  onChange={(e) => this.getFilterInputData(e, "limit")}
                >
                  {KYC_LIMIT.map((limit) => {
                    return <option value={limit.value}>{limit.name}</option>;
                  })}
                </Form.Control>
              </Form.Group>
            </Col>

          
            <Col xl={2} sm={4} xs={12} text-nowrap className="text-nowrap">
             
            </Col>
          </Row>
          <Row>
            <Col></Col>
            <Col style={{ textAlign: "right" }}>
              <ImportCsv
                stateCsvData={this.state.stateCsvData}
                file_name={"Submitted Kyc"}
              />
            </Col>
          </Row> */}
          <Card className="mt-4">
            <Card.Body>
              <Table striped bordered hover responsive size="sm">
                <thead>
                  <tr
                    style={{
                      height: "3rem",
                      color: "#3d5170",
                    }}
                  >
                    <th className="align-middle">#</th>
                    <th className="align-middle">Name</th>
                    <th className="align-middle">Email Address</th>
                    <th className="text-center align-middle">
                      KYC Submitted Date
                    </th>
                    <th className="text-center align-middle">Status</th>
                    <th className="text-center align-middle">Action Date</th>
                  </tr>
                </thead>
                <tbody>
                  {submittedKycData.length > 0 ? (
                    submittedKycData.map((submitKycData, key) => {
                      return (
                        <tr>
                          <td className="text-center align-middle">
                            {(this.state.currentpage - 1) *
                              this.state.filterData.limit +
                              (parseFloat(key) + 1)}
                          </td>
                          {/* <td>{(i + 1)* parseInt(this.state.currentpage)}</td> */}
                          <td className="align-middle">
                            <Link
                              to={`/auth/kycDetail/${submitKycData.users_id}`}
                            >
                              {submitKycData?.firstname}{" "}
                              {submitKycData?.lastname}
                            </Link>
                          </td>

                          <td className="align-middle">
                            <Link
                              to={`/auth/trader-view/${submitKycData.users_id}`}
                            >
                              {submitKycData.email}
                            </Link>
                          </td>

                          <td className="align-middle">
                            {dateFormat(
                              convertTZ(
                                submitKycData?.submitted_at,
                                "Asia/Vientiane"
                              ),
                              "dd/mm/yyyy h:MM:ss TT"
                            )}
                          </td>

                          <td align="center" className="align-middle">
                            {this.kycStatus(submitKycData?.status) || "--"}
                          </td>
                          <td className="align-middle">
                            {submitKycData?.status === 1 &&
                              dateFormat(
                                convertTZ(
                                  submitKycData?.approved_at,
                                  "Asia/Vientiane"
                                ),
                                "dd/mm/yyyy h:MM:ss TT"
                              )}
                            {submitKycData?.status === 2 &&
                              dateFormat(
                                convertTZ(
                                  submitKycData?.declined_at,
                                  "Asia/Vientiane"
                                ),
                                "dd/mm/yyyy h:MM:ss TT"
                              )}
                            {submitKycData?.status === 3 &&
                              dateFormat(
                                convertTZ(
                                  submitKycData?.declined_at,
                                  "Asia/Vientiane"
                                ),
                                "dd/mm/yyyy h:MM:ss TT"
                              )}
                            {submitKycData?.status === 0 && "--"}
                            <br />
                            <small>{`${submitKycData?.adminFirstname || "--"} ${
                              submitKycData?.adminlastname || "--"
                            }`}</small>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr style={{ height: "8rem" }}>
                      <td colSpan="6" className="text-center align-middle">
                        No Record Found
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
              <Row className="justify-content-space-between mt-2">
                <Col>
                  <Dropdown>
                    <Dropdown.Toggle
                      size="sm"
                      variant="outline-secondary"
                      id="pagesize-dropdown"
                    >
                      <Icon name="caret up" />
                      {`Show ${this.state.filterData.limit} items/page`}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() => this.handleChangePageSize(50)}
                      >
                        Show 50 items
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => this.handleChangePageSize(100)}
                      >
                        Show 100 items
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => this.handleChangePageSize(150)}
                      >
                        Show 150 items
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => this.handleChangePageSize(250)}
                      >
                        Show 250 items
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => this.handleChangePageSize(500)}
                      >
                        Show 500 items
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
                <Col className={"d-flex justify-content-end"}>
                  <Pagination
                    size="mini"
                    activePage={this.state.currentpage}
                    onPageChange={this.pageChange}
                    ellipsisItem={{
                      content: <Icon name="ellipsis horizontal" />,
                      icon: true,
                    }}
                    firstItem={{
                      content: <Icon name="angle double left" />,
                      icon: true,
                    }}
                    lastItem={{
                      content: <Icon name="angle double right" />,
                      icon: true,
                    }}
                    prevItem={{
                      content: <Icon name="angle left" />,
                      icon: true,
                    }}
                    nextItem={{
                      content: <Icon name="angle right" />,
                      icon: true,
                    }}
                    totalPages={Math.ceil(
                      kycCount / this.state.filterData.limit
                    )}
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
          {/* <Row className="justify-content-end mt-4 p-3 bg-white">
            {submittedKycData.length > 1 ? (
              <Pagination
                defaultActivePage={1}
                activePage={this.state.currentpage}
                onPageChange={this.pageChange}
                ellipsisItem={{
                  content: <Icon name="ellipsis horizontal" />,
                  icon: true,
                }}
                firstItem={{
                  content: <Icon name="angle double left" />,
                  icon: true,
                }}
                lastItem={{
                  content: <Icon name="angle double right" />,
                  icon: true,
                }}
                prevItem={{ content: <Icon name="angle left" />, icon: true }}
                nextItem={{ content: <Icon name="angle right" />, icon: true }}
                totalPages={Math.ceil(kycCount / this.state.filterData.limit)}
              />
            ) : (
              ""
            )}
          </Row> */}
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    usersList: state.user.usersList,
    activeUsers: state.user.activeUsers,
    totalRecords: state.user.totalRecords,
    submittedKycData: state.user.submittedKycData,
    kycCount: state.user.kycCount,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //   dashboardDetailsFn: (data) => dispatch(dashboardDetailsFn(data))
    getUsersFn: (data) => dispatch(getUsersFn(data)),
    getActiveUsersFn: (data) => dispatch(getActiveUsersFn(data)),
    userActionFn: (data) => dispatch(userActionFn(data)),
    sendAuthKeyFn: (data) => dispatch(sendAuthKeyFn(data)),
    filterUsersFn: (data) => dispatch(filterUsersFn(data)),
    submittedKycFn: (data) => dispatch(submittedKycFn(data)),
    getKycCountFn: (data) => dispatch(getKycCountFn(data)),
    getCsvRecordFn: (data) => dispatch(getCsvRecordFn(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);
