import React from "react";
import { Nav } from "shards-react";

import { NavLink as RouteNavLink } from "react-router-dom";
import { NavItem, NavLink } from "shards-react";

import { connect } from "react-redux";
import { Badge } from "react-bootstrap";
import { PERMISSONS } from "../../../Constants/constant";
import { getPendingWithdraw } from "../../../Redux/Actions/withdrawTransactions/withdrawTrans.action";
import { getPendingSell } from "../../../Redux/Actions/pair/pair.action";
class SidebarNavItems extends React.Component {
  constructor(props) {
    super(props);
    // console.log("props",this.props)
    // super(props);
    // signInDetails().then(res => {
    // console.log("propss",this.props)
    var selectedOptions = [];
    if (this.props.userData.accessRole == 1) {
      selectedOptions = this.props.userData.role.split(",");
    } else {
      PERMISSONS.forEach((element) => {
        selectedOptions.push(element.name);
      });
    }

    // })
    this.state = {
      selectedMenuBar: selectedOptions,
      isActive: false,
      isActivetest: false,
      isHasPendingWithdraw: 0,
      isHasPendingSellCrypto: 0,
    };
  }
  componentDidMount = () => {
    // Call the functions immediately when the component is mounted
    this.fetchPendingData();

    // Then, set up the interval to call the functions every 1 minute
    this.intervalId = setInterval(this.fetchPendingData, 60000); // 60000 milliseconds = 1 minute
  };

  // Separate the data fetching logic into a reusable function
  fetchPendingData = () => {
    this.props.getPendingWithdraw().then(({ data }) => {
      this.setState({ isHasPendingWithdraw: data.total });
    });

    this.props.getPendingSell().then(({ data }) => {
      this.setState({ isHasPendingSellCrypto: data.total });
    });
  };

  // Clean up the interval when the component is unmounted
  componentWillUnmount = () => {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  };

  handleToggle = () => {
    this.setState({ isActive: !this.state.isActive });
  };

  handleToggletest = () => {
    this.setState({ isActivetest: !this.state.isActivetest });
  };

  render() {
    let pathname =
      window.location.pathname == "/admin/auth/currency-manage"
        ? "nav-link active"
        : "";
    // console.log("selectedMenuBar",window.location.pathname)

    return (
      <div className="nav-wrapper">
        <Nav className="nav--no-borders flex-column">
          <NavItem>
            <NavLink tag={RouteNavLink} to="/auth/dashboard">
              <span>Dashboard</span>
            </NavLink>
          </NavItem>

          {this.state.selectedMenuBar.includes("manage_subadmin") && (
            <NavItem>
              <NavLink
                tag={RouteNavLink}
                to="/auth/sub-admin-management/sub-admin-users"
              >
                <span>Manage SubAdmin</span>
              </NavLink>
            </NavItem>
          )}

          {this.state.selectedMenuBar.includes("traders") && (
            <NavItem>
              <NavLink tag={RouteNavLink} to="/auth/traders">
                <span>Traders</span>
              </NavLink>
            </NavItem>
          )}
          {this.state.selectedMenuBar.includes("submitted_kyc") && (
            <NavItem>
              <NavLink tag={RouteNavLink} to="/auth/submittedKyc">
                <span>KYC</span>
              </NavLink>
            </NavItem>
          )}

          {/* {this.state.selectedMenuBar.includes("approved_kyc") && (
            <NavItem>
              <NavLink tag={RouteNavLink} to="/auth/approvedKyc">
                <div className="d-inline-block item-icon-wrapper">
                  <i class="material-icons"></i>
                </div>
                <span>Approved KYC</span>
              </NavLink>
            </NavItem>
          )} */}

          {this.state.selectedMenuBar.includes("withdraw_transaction") && (
            <NavItem>
              <NavLink tag={RouteNavLink} to="/auth/withdrawal_transactions">
                <span>Withdraw Request</span>
                {this.state.isHasPendingWithdraw > 0 ? (
                  <>
                    <Badge className="ml-2" variant="danger">
                      {this.state.isHasPendingWithdraw}
                    </Badge>
                    <span className="sr-only">unread messages</span>
                  </>
                ) : (
                  ""
                )}
              </NavLink>
            </NavItem>
          )}
          {this.state.selectedMenuBar.includes("deposit_transaction") && (
            <NavItem>
              <NavLink
                onClick={() =>
                  this.setState({
                    visible: false,
                  })
                }
                tag={RouteNavLink}
                to="/auth/deposite_transaction"
              >
                <span>Deposit Transaction</span>
              </NavLink>
            </NavItem>
          )}

          {this.state.selectedMenuBar.includes("orders_management") && (
            <NavItem>
              <NavLink tag={RouteNavLink} to="/auth/order-list">
                <span>Order Listing</span>
              </NavLink>
            </NavItem>
          )}
          {this.state.selectedMenuBar.includes("trade_summary") && (
            <NavItem>
              <NavLink tag={RouteNavLink} to="/auth/trade-summary">
                <span>Trade Summary</span>
              </NavLink>
            </NavItem>
          )}

          {this.state.selectedMenuBar.includes("currency_management") && (
            <NavItem>
              <NavLink
                className={pathname}
                tag={RouteNavLink}
                to="/auth/currency-manage/"
              >
                <span>Currency</span>
              </NavLink>
            </NavItem>
          )}
          {this.state.selectedMenuBar.includes("currency_pairs") && (
            <NavItem>
              <NavLink tag={RouteNavLink} to="/auth/currency-pairs">
                <span>Currency Pairs</span>
              </NavLink>
            </NavItem>
          )}

          {this.state.selectedMenuBar.includes("swap_pairs") && (
            <NavItem>
              <NavLink tag={RouteNavLink} to="/auth/swappair">
                <span>Swap Pairs</span>
              </NavLink>
            </NavItem>
          )}

          {/* {this.state.selectedMenuBar.includes("swap_orders") && (
            <NavItem>
              <NavLink tag={RouteNavLink} to="/auth/swaporder">
                <span>Buy Crypto</span>
              </NavLink>
            </NavItem>
          )}

          {this.state.selectedMenuBar.includes("sell_orders") && (
            <NavItem>
              <NavLink tag={RouteNavLink} to="/auth/sellorder">
                <span>Sell Crypto</span>
              </NavLink>
            </NavItem>
          )} */}
          {/* <NavItem onClick={() => this.handleToggle()}>
            <NavLink>
              <span
                style={{ cursor: "pointer" }}
                className="d-flex justify-content-between"
              >
                <div>
                  Crypto
                  {this.state.isHasPendingSellCrypto > 0 ? (
                    <>
                      <Badge className="ml-2" variant="danger">
                        {this.state.isHasPendingSellCrypto}
                      </Badge>
                      <span className="sr-only">unread messages</span>
                    </>
                  ) : (
                    ""
                  )}
                </div>
                <span className="arrowRotate text-right">&#62;</span>
              </span>
            </NavLink>
          </NavItem>
          <ul
            className={`dropdown__list ${
              this.state.isActive ? "d-block" : "d-none"
            }`}
          >
            {this.state.selectedMenuBar.includes("sell_orders") && (
              <li className="dropdown__item"> */}
          <NavItem>
            <NavLink
              tag={RouteNavLink}
              to="/auth/sellorder"
              className="dropdown__link"
            >
              Sell Crypto
              {this.state.isHasPendingSellCrypto > 0 ? (
                <>
                  <Badge className="ml-2" variant="danger">
                    {this.state.isHasPendingSellCrypto}
                  </Badge>
                  <span className="sr-only">unread messages</span>
                </>
              ) : (
                ""
              )}
            </NavLink>
          </NavItem>
          {/* </li>
            )} */}
          {/* {this.state.selectedMenuBar.includes("swap_orders") && (
              <li className="dropdown__item"> */}
          <NavItem>
            <NavLink
              tag={RouteNavLink}
              to="/auth/swaporder"
              className="dropdown__link"
            >
              Buy Crypto
            </NavLink>
          </NavItem>
          {/* </li>
            )}
          </ul> */}
          <NavItem onClick={() => this.handleToggletest()}>
            <NavLink>
              <span
                style={{ cursor: "pointer" }}
                className="d-flex justify-content-between"
              >
                Liquidity Balance
                <span className="arrowRotate">&#62;</span>
              </span>
            </NavLink>
          </NavItem>
          <ul
            className={`dropdown__list ${
              this.state.isActivetest ? "d-block" : "d-none"
            }`}
          >
            {this.state.selectedMenuBar.includes("binance_balance") && (
              <li className="dropdown__item">
                <NavItem>
                  <NavLink
                    tag={RouteNavLink}
                    to="/auth/fireblocks"
                    className="dropdown__link"
                  >
                    Fireblocks
                  </NavLink>
                </NavItem>
              </li>
            )}
            {this.state.selectedMenuBar.includes("fireblocks_balance") && (
              <li className="dropdown__item">
                <NavItem>
                  <NavLink
                    tag={RouteNavLink}
                    to="/auth/binance"
                    className="dropdown__link"
                  >
                    Binance
                  </NavLink>
                </NavItem>
              </li>
            )}
          </ul>
          {this.state.selectedMenuBar.includes("Withdrow") && (
            <NavItem>
              <NavLink
                tag={RouteNavLink}
                className={`${
                  window.location.pathname === "/admin/auth/pnl/sell"
                    ? "active"
                    : window.location.pathname === "/admin/auth/pnl/buy"
                    ? "active"
                    : window.location.pathname === "/admin/auth/pnl/withdrow"
                    ? "active"
                    : window.location.pathname === "/admin/auth/pnl/exchanges"
                    ? "active"
                    : null
                } `}
                to="/auth/pnl/withdrow"
              >
                <span>PNL</span>
              </NavLink>
            </NavItem>
          )}
        </Nav>
      </div>
    );
  }
}

//export default SidebarNavItems;

const mapStateToProps = (state) => {
  return {
    userData: require("jsonwebtoken").decode(state.auth.tokens),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPendingWithdraw: () => dispatch(getPendingWithdraw()),
    getPendingSell: () => dispatch(getPendingSell()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SidebarNavItems);
