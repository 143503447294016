import dateFormat from "dateformat";
import React from "react";
import {
  Badge,
  Button,
  Card,
  Col,
  Container,
  Dropdown,
  Form,
  Row,
  Table,
} from "react-bootstrap";
import { Icon, Pagination } from "semantic-ui-react";
import { activeCoinsFn } from "../../../Redux/Actions/dashboard/dashboard.action";
import { Link } from "react-router-dom";

import {
  depositeCSVFilterDataFn,
  depositeFilterDataFn,
  getAllCSVDepositesFn,
  getAllDepositesFn,
} from "../../../Redux/Actions/DepositeTrans/deposite.action";

import "moment/locale/en-gb";
import "rc-datepicker/lib/style.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import { DEPOSITE_STATUS, limit } from "../../../Constants/constant";
//import "./Users.css";
import "../../../App.css";

import { CSVLink } from "react-csv";
import { convertTZ } from "./../../common/helper";

class Users extends React.Component {
  constructor() {
    super();
    this.state = {
      disabled: true,
      stateCsvData: [],
      depositeCsvList: [],
      totalDataCount: 0,
      enableFilterInput: true,
      filterOtion: false,
      setsearchcoin: "",
      resetcoin: "eth",
      resetdata: false,
      page: 1,
      activePage: 1,
      coin: "eth",
      pageload: false,
      coinfirstime: "",
      currencyCoins: "",
      total_recoder: 0,
      filterData: {
        address: "",
        email: "",
        status: "",
        txid: "",
        fromDate: "",
        toDate: "",
        isCoin: "",
        limit: limit,
      },
      // add is loading csv
      isLoadingCSV: false,
      isDateRangeInvalid: false,
    };
    this.csvInstance = React.createRef();
  }

  componentDidMount = () => {
    let { activeCoinsFn } = this.props;
    activeCoinsFn();
    this.searchValues(this.state.coin);
    this.setState({
      coin: "",
      enableFilterInput: false,
      filterOtion: false,
      pageload: true,
    });
  };

  clearSearch = () => {
    this.setState({
      page: 1,
      isDateRangeInvalid: false,
    });
    setTimeout(() => {
      this.searchValues();
    });
    this.setState({
      resetcoin: "",
      coin: "",
      enableFilterInput: false,
    });
    this.setState({ filterOtion: false });
    let data = this.state.filterData;

    data["coin"] = "";
    data["email"] = "";
    data["address"] = "";
    data["txid"] = "";
    data["status"] = "";
    data["fromDate"] = "";
    data["toDate"] = "";
    this.setState({
      filterData: data,
    });
  };
  handleSearch = () => {
    // set date range valid
    const { fromDate, toDate } = this.state.filterData;
    if ((fromDate && toDate) || (!fromDate && !toDate)) {
      this.setState(
        { ...this.state, page: 1, isDateRangeInvalid: false },
        () => {
          this.searchValues();
        }
      );
      return;
    }
    this.setState({ ...this.state, isDateRangeInvalid: true });

    // this.setState({ ...this.state, page: 1 }, () => {
    //   this.searchValues();
    // });
  };
  // on change page size
  handleChangePageSize = (pageSize) => {
    this.setState(
      { filterData: { ...this.state.filterData, limit: pageSize } },
      () => {
        // call back to load new user list with filter state
        this.searchValues();
      }
    );
  };

  pageChange = (e, data) => {
    let page = data.activePage;
    this.setState(
      {
        page: page,
      },
      () => this.searchValues()
    );
    // this.searchValues();
    // this.props.getAllDepositesFn(searchedParams);
  };

  searchValues = () => {
    this.state.depositeCsvList = "";
    let { depositeFilterDataFn } = this.props;
    let { filterData } = this.state;
    let filterDatas = {
      address: filterData.address,
      email: filterData.email,
      status: filterData.status,
      txid: filterData.txid,
      page: this.state.page,
      fromDate: filterData.fromDate
        ? dateFormat(filterData.fromDate, "yyyy-mm-dd")
        : "",
      toDate: filterData.toDate
        ? dateFormat(filterData.toDate, "yyyy-mm-dd 23:59:59")
        : "",
      limit: this.state.filterData.limit,
      isCoin: "",
    };

    depositeFilterDataFn(this.state.coin || "eth", filterDatas);

    this.setState({
      filterOtion: true,
      setsearchcoin: this.state.coin,
      pageload: false,
    });
  };

  // export as csv function
  getDepositCSVData = () => {
    this.setState({ CSVDataList: "", isLoadingCSV: true });
    let { filterData } = this.state;

    let filterParams = {
      address: filterData.address,
      email: filterData.email,
      status: filterData.status,
      txid: filterData.txid,
      page: 1,
      fromDate: filterData.fromDate
        ? dateFormat(filterData.fromDate, "yyyy-mm-dd")
        : "",
      toDate: filterData.toDate
        ? dateFormat(filterData.toDate, "yyyy-mm-dd 23:59:59")
        : "",
      isCoin: "",
      limit: this.props.totalRecords,
    };
    this.props
      .depositeCSVFilterDataFn(this.state.coin || "eth", {
        ...filterParams,
        isCoin: !this.state.coin ? "" : filterData.isCoin,
      })
      .then((res) => {
        const formattedData = res.map((stateData, index) => ({
          "#": index + 1,
          Date: dateFormat(stateData?.created_at, "dd-mm-yyyy h:MM:ss TT"),
          Status:
            stateData.status == 2
              ? "Confirmed"
              : stateData.status == 1
              ? "Unconfirmed"
              : stateData.status == 0 && "Pending",
          email: stateData.email,

          Coin: stateData.coin ? stateData.coin : this.state.coin.toUpperCase(),
          Amount: stateData.amount / 100000000,
          "Tx ID": stateData.tx_id,
          "Address From": stateData.address_from,
          "Address To": stateData.address_to,
          // Time: dateFormat(
          //   convertTZ(stateData?.created_at, "Asia/Vientiane"),
          //   "h:MM:ss TT"
          // ),
        }));
        this.setState({ stateCsvData: formattedData, isLoadingCSV: false });
      })
      .catch((err) => {
        this.setState({ isLoadingCSV: false });
      });
  };
  // trigger csv download after fetched data
  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.stateCsvData.length &&
      this.csvInstance.current &&
      this.csvInstance.current.link
    ) {
      setTimeout(() => {
        this.csvInstance.current.link.click();
        this.setState({ stateCsvData: [], isLoadingCSV: false });
      });
    }
  }

  getValue = (e) => {
    if (e == "") {
      this.setState({ disabled: true });
    } else {
      this.setState({ disabled: false });
    }
  };

  getFilterInputData = (e, type) => {
    let { filterData } = this.state;
    this.setState({
      currencyCoins: e,
      page: 1,
    });
    //this.getValue(e.target.value);
    if (type == "coin") {
      //filterData.coin = e.target.value;
      if (e.target.value !== "") {
        this.setState({ enableFilterInput: false });
        this.setState({ coin: e.target.value });
        filterData.coin = e.target.value;
        filterData.isCoin = 1;
      } else {
        this.setState({ enableFilterInput: true });
      }
    } else if (type == "email") filterData.email = e.target.value;
    else if (type == "address") {
      filterData.address = e.target.value;
    } else if (type == "status") {
      filterData.status = e.target.value;
    } else if (type == "fromDate") {
      filterData.fromDate = e;
    } else if (type == "toDate") {
      filterData.toDate = e;
    }

    this.setState({ filterData: filterData });
  };

  depositeCSVList = () => {
    let statementCsvData = [];
    if (
      this.state.depositeCsvList !== undefined &&
      this.state.depositeCsvList.length > 0
    ) {
      this.state.depositeCsvList.map((stateData) => {
        let data = {
          "Tx ID": stateData.tx_id,
          Coin: stateData.coin ? stateData.coin : this.state.coin.toUpperCase(),
          email: stateData.email,
          Amount: stateData.amount / 100000000,
          "Address From": stateData.address_from,
          "Address To": stateData.address_to,
          Date: dateFormat(stateData?.created_at, "dd-mm-yyyy"),
          Time: dateFormat(
            convertTZ(stateData?.created_at, "Asia/Vientiane"),
            "h:MM:ss TT"
          ),
          Status:
            stateData.status == 2
              ? "Confirmed"
              : stateData.status == 1
              ? "Unconfirmed"
              : stateData.status == 0 && "Pending",
        };

        statementCsvData.push(data);
      });
      this.setState({
        stateCsvData: statementCsvData,
        // dataLength: nextProps.customerDataLength,
      });
    } else {
      this.setState({
        stateCsvData: "",
        // dataLength: nextProps.customerDataLength,
      });
    }
  };

  render() {
    let { totalRecords, activeCoins, allDeposites } = this.props;
    let { filterData } = this.state;
    this.state.total_recoder = totalRecords;
    // this.setState({
    //   total_recoder: totalRecords,
    //   // dataLength: nextProps.customerDataLength,
    // });
    function preciseTodecimal(num) {
      var exponentialNumber = num / 10 ** 8;

      const str = exponentialNumber.toString();
      if (str.indexOf("e") !== -1) {
        const exponent = parseInt(str.split("-")[1], 8);
        const result = exponentialNumber.toFixed(8);

        return result;
      } else {
        return exponentialNumber;
      }
    }

    let { enableFilterInput } = this.state;
    return (
      <div>
        <Container fluid className={"py-4"}>
          <Card>
            <Card.Body>
              <Row className="page-header pb-3 justify-content-space-between">
                <Col>
                  <h3 className="page-title">{"Deposit Transaction"}</h3>
                </Col>
                <Col className={"d-flex justify-content-end"}>
                  {/* react csv */}
                  <>
                    <div onClick={this.getDepositCSVData}>
                      <Button
                        variant="white"
                        disabled={this.state.isLoadingCSV}
                      >
                        <Icon name="download" />
                        {this.state.isLoadingCSV
                          ? "Loading..."
                          : "Export as CSV"}
                      </Button>
                    </div>
                    {this.state.stateCsvData.length ? (
                      <CSVLink
                        data={this.state.stateCsvData}
                        filename={`deposit${new Date().toISOString()}.csv`}
                        ref={this.csvInstance}
                      />
                    ) : undefined}
                  </>
                </Col>
              </Row>
              <Row>
                <Col lg={3} md={4} xs={12}>
                  <Form.Group controlId="formGridState">
                    <Form.Label>Coin</Form.Label>
                    <Form.Control
                      as="select"
                      defaultValue={this.state.coin}
                      value={this.state.coin}
                      name="coin"
                      id="coin"
                      onChange={(e) => this.getFilterInputData(e, "coin")}
                    >
                      {this.state.coin == "" && (
                        <option className="d-none" value="">
                          Select Coin
                        </option>
                      )}
                      {activeCoins != undefined &&
                        activeCoins &&
                        activeCoins.map((coins, index) => {
                          return (
                            <option key={index} value={coins}>
                              {coins.toUpperCase()}
                            </option>
                          );
                        })}
                    </Form.Control>
                  </Form.Group>
                </Col>

                <Col lg={3} md={4} xs={12}>
                  <Form.Group>
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="search"
                      name="email"
                      id="email"
                      value={filterData.email}
                      placeholder="Email"
                      onChange={(e) => this.getFilterInputData(e, "email")}
                      disabled={enableFilterInput}
                    />
                  </Form.Group>
                </Col>
                <Col lg={3} md={4} xs={12}>
                  <Form.Group>
                    <Form.Label>Address</Form.Label>
                    <Form.Control
                      type="search"
                      name="address"
                      id="address"
                      value={filterData.address}
                      placeholder="Address"
                      onChange={(e) => this.getFilterInputData(e, "address")}
                      disabled={enableFilterInput}
                    />
                  </Form.Group>
                </Col>
                <Col lg={3} md={4} xs={12}>
                  <Form.Group>
                    <Form.Label>From Date</Form.Label>

                    <DatePicker
                      dateFormat="dd-MM-yyyy"
                      selected={filterData.fromDate ? filterData.fromDate : ""}
                      placeholderText="DD/MM/YYYY"
                      className="form-control"
                      onChange={(e) => this.getFilterInputData(e, "fromDate")}
                    />
                    <Form.Text type="danger" muted>
                      {this.state.isDateRangeInvalid &&
                      !this.state.filterData.fromDate ? (
                        <span style={{ color: "red" }}>
                          Please select from date
                        </span>
                      ) : (
                        ""
                      )}
                    </Form.Text>
                  </Form.Group>
                </Col>
                <Col lg={3} md={4} xs={12}>
                  <Form.Group>
                    <Form.Label>To Date</Form.Label>
                    <DatePicker
                      disabled={!filterData?.fromDate}
                      minDate={new Date(filterData.fromDate)}
                      dateFormat="dd-MM-yyyy"
                      selected={filterData.toDate ? filterData.toDate : ""}
                      placeholderText="DD/MM/YYYY"
                      className="form-control"
                      onChange={(e) => this.getFilterInputData(e, "toDate")}
                    />
                    <Form.Text muted>
                      {this.state.isDateRangeInvalid &&
                      !this.state.filterData.toDate ? (
                        <span style={{ color: "red" }}>
                          Please select to date
                        </span>
                      ) : (
                        ""
                      )}
                    </Form.Text>
                  </Form.Group>
                </Col>
                <Col lg={3} md={4} xs={12}>
                  <Form.Group>
                    <Form.Label>Status</Form.Label>
                    <Form.Control
                      as="select"
                      defaultValue="Status"
                      name="status"
                      id="status"
                      value={this.state.filterData.status}
                      onChange={(e) => this.getFilterInputData(e, "status")}
                      disabled={enableFilterInput}
                    >
                      {this.state.filterData.status == "" && (
                        <option className="d-none" value="">
                          All
                        </option>
                      )}

                      {DEPOSITE_STATUS.map((status) => {
                        return (
                          <option value={status.value}>{status.name}</option>
                        );
                      })}
                    </Form.Control>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col
                // className={"d-flex justify-content-center mt-2"}
                >
                  <Button
                    variant="primary"
                    type="submit"
                    // disabled={this.state.disabled}
                    onClick={this.handleSearch}
                  >
                    Search
                  </Button>

                  <Button
                    variant="danger"
                    className="ml-2"
                    onClick={this.clearSearch}
                  >
                    Reset
                  </Button>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <Card className="mt-4">
            <Card.Body>
              <Table striped bordered hover responsive size="sm">
                <thead>
                  <tr
                    style={{
                      height: "3rem",
                      color: "#3d5170",
                    }}
                  >
                    <th className="text-center align-middle">#</th>
                    <th className="text-center align-middle">Date</th>
                    <th className="align-middle">Email ID</th>
                    <th className="text-center align-middle">Coin</th>
                    <th className="text-center align-middle">Amount</th>
                    <th className="align-middle">Tx ID</th>
                    <th className="align-middle">Address from</th>
                    <th className="align-middle">Address To</th>
                    <th className="text-center align-middle">Action</th>

                    {/* <th className="align-middle">Time</th> */}
                  </tr>
                </thead>
                <tbody>
                  {allDeposites.length > 0 ? (
                    allDeposites.map((depositeData, key) => {
                      return (
                        <tr>
                          <td className="text-center align-middle">
                            {(this.state.page - 1) *
                              this.state.filterData.limit +
                              (key + 1)}
                          </td>
                          <td className="align-middle">
                            {dateFormat(
                              convertTZ(
                                depositeData?.created_at,
                                "Asia/Vientiane"
                              ),
                              "dd/mm/yyyy h:MM:ss TT"
                            )}
                          </td>

                          <td className="align-middle">
                            <Link
                              to={`/auth/trader-view/${depositeData.userId}`}
                            >
                              {depositeData.email}
                            </Link>
                          </td>
                          {depositeData.coin ? (
                            <td className="text-right align-middle">
                              {depositeData.coin}
                            </td>
                          ) : (
                            <td className="text-right align-middle">
                              {this.state.resetdata == true
                                ? this.state.resetcoin
                                : this.state.filterOtion == true
                                ? this.state.setsearchcoin.toUpperCase()
                                : this.state.filterOtion == false
                                ? this.state.resetcoin.toUpperCase()
                                : this.state.coin.toUpperCase()}
                            </td>
                          )}
                          <td className="text-right align-middle">
                            {preciseTodecimal(depositeData.amount)}
                          </td>
                          <td className="align-middle">{depositeData.tx_id}</td>
                          <td className="align-middle">
                            {depositeData.address_from}
                          </td>
                          <td className="align-middle">
                            {depositeData.address_to}
                          </td>
                          <td className="align-middle">
                            {depositeData.status == 1 ? (
                              <Badge variant="danger">Unconfirmed</Badge>
                            ) : depositeData.status == 2 ? (
                              <Badge variant="success">Confirmed</Badge>
                            ) : depositeData.status == 0 ? (
                              <Badge variant="warning">Pending</Badge>
                            ) : (
                              ""
                            )}
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr style={{ height: "8rem" }}>
                      <td colSpan="9" className="text-center align-middle">
                        No Record Found
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
              <Row className="justify-content-space-between mt-2">
                <Col>
                  <Dropdown>
                    <Dropdown.Toggle
                      size="sm"
                      variant="outline-secondary"
                      id="pagesize-dropdown"
                    >
                      <Icon name="caret up" />
                      {`Show ${this.state.filterData.limit} items/page`}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() => this.handleChangePageSize(50)}
                      >
                        Show 50 items
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => this.handleChangePageSize(100)}
                      >
                        Show 100 items
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => this.handleChangePageSize(150)}
                      >
                        Show 150 items
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => this.handleChangePageSize(250)}
                      >
                        Show 250 items
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => this.handleChangePageSize(500)}
                      >
                        Show 500 items
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
                <Col className={"d-flex justify-content-end"}>
                  <Pagination
                    size="mini"
                    defaultActivePage={1}
                    activePage={this.state.page}
                    onPageChange={this.pageChange}
                    ellipsisItem={{
                      content: <Icon name="ellipsis horizontal" />,
                      icon: true,
                    }}
                    firstItem={{
                      content: <Icon name="angle double left" />,
                      icon: true,
                    }}
                    lastItem={{
                      content: <Icon name="angle double right" />,
                      icon: true,
                    }}
                    prevItem={{
                      content: <Icon name="angle left" />,
                      icon: true,
                    }}
                    nextItem={{
                      content: <Icon name="angle right" />,
                      icon: true,
                    }}
                    totalPages={Math.ceil(
                      totalRecords / this.state.filterData.limit
                    )}
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    totalRecords: state.deposite.totalRecords,
    activeCoins: state.dashboard.activeCoins,
    allDeposites: state.deposite.allDeposites,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    activeCoinsFn: (data) => dispatch(activeCoinsFn(data)),
    getAllDepositesFn: (data) => dispatch(getAllDepositesFn(data)),
    depositeFilterDataFn: (coin, data) =>
      dispatch(depositeFilterDataFn(coin, data)),
    depositeCSVFilterDataFn: (coin, data) =>
      dispatch(depositeCSVFilterDataFn(coin, data)),
    getAllCSVDepositesFn: (data) => dispatch(getAllCSVDepositesFn(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);
