import dateFormat from "dateformat";
import React, { Component } from "react";
import {
  Badge,
  Button,
  Card,
  Image,
  Modal,
  Table,
  ButtonGroup,
} from "react-bootstrap";

import ContentEditable from "react-contenteditable";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import sanitizeHtml from "sanitize-html";
import { Col, Container, Row } from "shards-react";
import {
  getImage,
  kycActionFn,
  singleKycDataFn,
} from "../../../Redux/Actions/user/user.action";
import "./kycfile.css";

import ReactImageMagnify from "react-image-magnify";

import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

export const REJECT_STATUS = [
  {
    name: "The submitted Name does not match the document",
    value: "The submitted Name does not match the document",
  },
  {
    name: "The submitted Date of Birth does not match the document",
    value: "The submitted Date of Birth does not match the document",
  },
  {
    name: "The submitted Gender does not match the document",
    value: "The submitted Gender does not match the document",
  },
  {
    name: "The submitted Image is invalid for the document",
    value: "The submitted Image is invalid for the document",
  },
  {
    name: "The submitted Image is not clear",
    value: "The submitted Image is not clear",
  },
  { name: "Insufficent submitted data", value: "Insufficent submitted data" },
  {
    name: "The submitted data does not match the country name",
    value: "The submitted data does not match the country name",
  },
  { name: "The document ID is invalid", value: "The document ID is invalid" },
  { name: "The document is expired", value: "The document is expired" },
];
class EditUser extends Component {
  constructor() {
    super();
    this.state = {
      user_id: "",
      offset: 0,
      limit: 2,
      kyc_id: "",
      approvepopup: false,
      approvekycid: "",
      deletedapprovepopup: false,
      deletedapproveid: "",
      nationalImage: "",
      imagePath: [],
      selectedImage: "",
      showImageModal: false,
      kycRejectReason: "The submitted Name does not match the document",
      kycExtension: "",
      html: ``,
      editable: true,
      showImg: {
        name: "",
        imgPath:
          "https://devtalk.blender.org/uploads/default/optimized/2X/c/cbd0b1a6345a44b58dda0f6a355eb39ce4e8a56a_2_690x460.png",
      },
      imgScale: 1,
      imgRotate: 0,
      imgFlip: false,
      imgWidth: 600, // Initial width
      imgHeight: 400, // Initial height
      dragX: 0, // X-axis for dragging
      dragY: 0, // Y-axis for dragging
      isDragging: false,
      startX: 0,
      startY: 0,
    };
  }
  // componentWillMount = () => {
  //   let { singleKycDataFn, singleKycData } = this.props;
  //   let { userId } = this.props.match.params;
  //   singleKycDataFn(userId);
  //   this.getSelfieImage(singleKycData.selfiePath);
  //   console.log("called every",singleKycData )
  // }
  // Image Function
  handleZoomIn() {
    this.setState({
      ...this.state,
      imgScale: this.state.imgScale + 0.1,
      imgWidth: this.state.imgWidth * 1.1,
      imgHeight: this.state.imgHeight * 1.1,
    });
  }
  handleZoomOut() {
    this.setState({
      ...this.state,
      imgScale: Math.max(this.state.imgScale - 0.1, 1),
      imgWidth: Math.max(this.state.imgWidth * 0.9, 600),
      imgHeight: Math.max(this.state.imgHeight * 0.9, 400),
    });
  }
  handleRotate() {
    this.setState({
      ...this.state,
      imgRotate: (this.state.imgRotate + 90) % 360,
    });
  }
  handleFlip() {
    this.setState({ ...this.state, imgFlip: !this.state.imgFlip });
  }

  // Drag start
  handleMouseDown = (e) => {
    e.preventDefault();
    this.setState({
      isDragging: true,
      startX: e.pageX - this.state.dragX,
      startY: e.pageY - this.state.dragY,
    });
  };

  // Drag movement
  handleMouseMove = (e) => {
    if (!this.state.isDragging) return;
    this.setState({
      dragX: e.pageX - this.state.startX,
      dragY: e.pageY - this.state.startY,
    });
  };

  // End dragging
  handleMouseUp = () => {
    this.setState({ isDragging: false });
  };

  handleChangeeditor = (evt) => {
    this.setState({ html: evt.target.value });
    this.setState({ kycRejectReason: evt.target.value });
  };

  sanitizeConf = {
    allowedTags: ["b", "i", "em", "strong", "a", "p", "h1"],
    allowedAttributes: { a: ["href"] },
  };

  sanitize = () => {
    this.setState({ html: sanitizeHtml(this.state.html, this.sanitizeConf) });
  };

  componentDidMount = () => {
    let { singleKycDataFn, singleKycData } = this.props;
    let { userId } = this.props.match.params;
    this.setState({ user_id: userId });
    singleKycDataFn(userId).then((res) => {
      this.getSelfieImage(singleKycData.selfiePath);
      this.getImage();
    });
  };

  getImage = () => {
    let { singleKycData } = this.props;

    // Sort the documents based on type using findImgTypeName before processing
    let sortedDocuments = singleKycData.documents?.sort((a, b) => {
      const nameA = `${a.type}`;
      const nameB = `${b.type}`;
      return nameA.localeCompare(nameB);
    });

    sortedDocuments?.forEach((element) => {
      var partialPath =
        "https://stage-bitqik.s3.ap-south-1.amazonaws.com" + element.s3_path;

      if (partialPath) {
        let newpath =
          "/" + partialPath.substring(partialPath.indexOf("uploads"));
        this.props.getImage({ image: newpath }).then((res) => {
          this.saveImgUrl(res.data.data, element.type, res.data.extention);
        });
      }
    });
  };

  saveImgUrl = (imgUrl, type, extension) => {
    let previousData = this.state.imagePath;
    this.setState({
      imagePath: [
        ...previousData,
        { imgPath: imgUrl, type: type, extension: extension },
      ],
      showImg: {
        name: this.findImgTypeName(this.state?.imagePath[0]?.type),
        imgPath: this.state?.imagePath[0]?.imgPath,
      },
    });
  };

  updateUserInfo = (values) => {
    values.user_id = this.state.user_id;
    this.props.editUserInfo(values);
  };

  updatekycDetail = (values) => {
    /* let kycData = {
            kyc_id:this.state.kyc_id,
            user_id:this.state.user_id
        }
        this.props.updateUserKycInfo(kycData) */
    alert("KYC FORM BUTTON");
  };

  approveKycStatus = () => {
    let kycData = {
      kyc_id: this.state.kyc_id,
      user_id: this.state.user_id,
      kyc_status: "1",
    };
    this.props.updateUserKycInfo(kycData);
  };

  rejectKycStatus = () => {
    let kycData = {
      kyc_id: this.state.kyc_id,
      user_id: this.state.user_id,
      kyc_status: "0",
    };
    this.props.updateUserKycInfo(kycData);
  };

  approveKycAction = (user_id) => {
    let { kycActionFn } = this.props;
    let kycActionParams = {
      id: user_id,
      isDeclined: "no",
    };
    kycActionFn(kycActionParams, this.props.history);
  };

  rejectKycAction = (user_id, kycReason) => {
    //console.log(kycReason, "kycReason");
    let { kycActionFn } = this.props;
    let kycActionParams = {
      id: user_id,
      isDeclined: "yes",
      reason: this.state.kycRejectReason,
    };
    //console.log(kycActionParams, "reject data")
    kycActionFn(kycActionParams, this.props.history);
  };
  approvepopupopen = (approveid) => {
    this.setState({
      approvekycid: approveid,
      approvepopup: true,
    });
  };
  rejectopenpopup = (rejectid) => {
    this.setState({
      deletedapproveid: rejectid,
      deletedapprovepopup: true,
    });
  };
  handleClose = () => {
    this.setState({
      approvepopup: false,
      deletedapprovepopup: false,
    });
  };

  getSelfieImage = (imagePath) => {
    let { singleKycData } = this.props;

    var partialPath =
      "https://stage-bitqik.s3.ap-south-1.amazonaws.com" +
      singleKycData.selfiePath;
    if (!!partialPath) {
      let newpath = "/" + partialPath.substring(partialPath.indexOf("uploads"));
      this.props.getImage({ image: newpath }).then((res) => {
        this.setState({ nationalImage: res.data.data });
      });
      // .catch((e) => console.log(e));
    }
  };

  kycStatus = (statusNumber, isPlanText = false) => {
    switch (statusNumber) {
      case 0:
        return isPlanText ? (
          "Submitted"
        ) : (
          <Badge variant="info">Submitted</Badge>
        );
      case 1:
        return isPlanText ? (
          "Approved"
        ) : (
          <Badge variant="success">Approved</Badge>
        );
      case 2:
        return isPlanText ? (
          "Declined"
        ) : (
          <Badge variant="danger">Declined</Badge>
        );
      case 3:
        return isPlanText ? (
          "Re-submitted"
        ) : (
          <Badge variant="warning">Re-submitted</Badge>
        );
      default:
        return "--";
        break;
    }
  };
  formattedText = (text) => {
    return text.split("\n").map((line, index) => (
      <span key={index}>
        {line}
        <br />
      </span>
    ));
  };

  defaultImagePath =
    "https://devtalk.blender.org/uploads/default/optimized/2X/c/cbd0b1a6345a44b58dda0f6a355eb39ce4e8a56a_2_690x460.png";

  // img slider
  settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
  };

  // mapImageTypeName
  findImgTypeName = (type) => {
    switch (type) {
      case 0:
        return "Passport Front";
      case 1:
        return "National ID";
      case 2:
        return "Identity Front";
      case 3:
        return "Identity Back";
      case 4:
        return "Passport Back";
      case 5:
        return "Selfie with Passport";
      case 6:
        return "Selfie with ID Card";
      default:
        return "Video";
    }
  };
  render() {
    let { singleKycData } = this.props;
    const { imgFlip, imgRotate } = this.state;

    return (
      <Container fluid className={"py-4"}>
        <Card>
          <Card.Body>
            <Row className="page-header pb-3 justify-content-space-between">
              <Col>
                <h3 className="page-title">{"KYC Detail"}</h3>
              </Col>
            </Row>
            <Row>
              <Col xs={12} lg={5}>
                {/* <Image
                  src={
                    this.state.nationalImage ||
                    "https://devtalk.blender.org/uploads/default/optimized/2X/c/cbd0b1a6345a44b58dda0f6a355eb39ce4e8a56a_2_690x460.png"
                  }
                  rounded
                  style={{ objectFit: "scale-down", width: "100%" }}
                />
                <h6>{this.state.showImg.name}</h6> */}

                {/* test */}
                <div>
                  <div
                    className="m-1"
                    style={{
                      height: "550px",
                      overflow: "hidden",
                    }}
                  >
                    <TransformWrapper
                      initialScale={1}
                      // initialPositionX={200}
                      // initialPositionY={100}
                    >
                      {({
                        zoomIn,
                        zoomOut,
                        resetTransform,
                        centerView,
                        ...rest
                      }) => (
                        <React.Fragment>
                          <div className="tools">
                            <ButtonGroup>
                              <Button
                                variant="outline-primary"
                                onClick={() => zoomIn()}
                              >
                                ZoomIn
                              </Button>

                              <Button
                                variant="outline-primary"
                                onClick={() => zoomOut()}
                              >
                                ZoomOut
                              </Button>
                              <Button
                                variant="outline-primary"
                                onClick={() => centerView()}
                              >
                                Center
                              </Button>

                              <Button
                                variant="outline-primary"
                                onClick={() => this.handleRotate()}
                              >
                                Rotate
                              </Button>
                              <Button
                                variant="outline-primary"
                                onClick={() => resetTransform()}
                              >
                                Reset
                              </Button>
                              {/* <Button
                                variant="outline-primary"
                                onClick={() => this.handleFlip()}
                              >
                                Flip
                              </Button> */}
                            </ButtonGroup>
                          </div>
                          <TransformComponent>
                            <Image
                              src={this.state.showImg.imgPath}
                              alt="Image"
                              style={{
                                height: "550px",
                                transform: `rotate(${imgRotate}deg) ${
                                  imgFlip ? "scaleX(-1)" : ""
                                }`,
                                transition: "transform 0.2s ease-in-out",
                              }}
                            />
                          </TransformComponent>
                        </React.Fragment>
                      )}
                    </TransformWrapper>
                    {/* <ReactImageMagnify
                      {...{
                        smallImage: {
                          alt: "Image",
                          isFluidWidth: true,
                          src: this.state.showImg.imgPath,
                          style: {
                            transform: `translate(${dragX}px, ${dragY}px) rotate(${imgRotate}deg) ${
                              imgFlip ? "scaleX(-1)" : ""
                            }`,
                            width: `${imgWidth}px`,
                            height: `${imgHeight}px`,
                            cursor: this.state.isDragging ? "grabbing" : "grab",
                            transition: !this.state.isDragging
                              ? "transform 0.2s ease-in-out"
                              : "none",
                          },
                        },
                        largeImage: {
                          src:
                            this.state.showImg.imgPath || this.defaultImagePath,
                          width: 1200,
                          height: 1800,
                          style: {
                            transform: `translate(${dragX}px, ${dragY}px) rotate(${imgRotate}deg) ${
                              imgFlip ? "scaleX(-1)" : ""
                            }`,
                            width: `${imgWidth}px`,
                            height: `${imgHeight}px`,
                            cursor: this.state.isDragging ? "grabbing" : "grab",
                            transition: !this.state.isDragging
                              ? "transform 0.2s ease-in-out"
                              : "none",
                          },
                        },
                        // enlargedImageContainerStyle: { zIndex: 9 },
                        imageStyle: {
                          borderRadius: "1rem",

                          // transform: `translate(${dragX}px, ${dragY}px) rotate(${imgRotate}deg) ${
                          //   imgFlip ? "scaleX(-1)" : ""
                          // }`,
                          // width: `${imgWidth}px`,
                          // height: `${imgHeight}px`,
                          // cursor: this.state.isDragging ? "grabbing" : "grab",
                          // transition: !this.state.isDragging
                          //   ? "transform 0.2s ease-in-out"
                          //   : "none",
                        },
                        // enlargedImagePosition: "over",
                      }}
                    /> */}
                  </div>
                  <h6 className="text-center">{this.state.showImg.name}</h6>
                  {/* <Row className="text-center mb-2">
                    <Col>
                      <ButtonGroup>
                        <Button
                          variant="primary"
                          onClick={() => this.handleZoomIn()}
                        >
                          Zoom In
                        </Button>
                        <Button
                          variant="secondary"
                          onClick={() => this.handleZoomOut()}
                        >
                          Zoom Out
                        </Button>
                        <Button
                          variant="warning"
                          onClick={() => this.handleRotate()}
                        >
                          Rotate
                        </Button>
                        <Button
                          variant="info"
                          onClick={() => this.handleFlip()}
                        >
                          Flip
                        </Button>
                      </ButtonGroup>
                    </Col>
                  </Row> */}
                  <div className="thumbnail-slider">
                    <Slider {...this.settings}>
                      {this.state.imagePath.map((image, index) => (
                        <div key={index}>
                          <Image
                            rounded
                            src={image.imgPath}
                            alt={`Thumbnail ${index + 1}`}
                            onClick={() =>
                              this.setState({
                                showImg: {
                                  imgPath: image.imgPath,
                                  name: this.findImgTypeName(image?.type),
                                },
                              })
                            }
                            style={{
                              cursor: "pointer",
                              width: "100px",
                              height: "100px",
                              objectFit: "cover",
                            }}
                          />
                          <div className="image-title text-center mt-3">
                            {this.findImgTypeName(image?.type)}
                          </div>
                        </div>
                      ))}
                    </Slider>
                  </div>
                </div>

                {/* end test */}
              </Col>
              <Col xs={12} lg={7}>
                <h6 className="mb-2 bold">Profile Info</h6>

                <Row>
                  <Col xs={12} xl={4} className={"text-center"}>
                    <Image
                      src={
                        this.state.nationalImage ||
                        "https://devtalk.blender.org/uploads/default/optimized/2X/c/cbd0b1a6345a44b58dda0f6a355eb39ce4e8a56a_2_690x460.png"
                      }
                      rounded
                      style={{ objectFit: "scale-down", width: 200 }}
                    />
                  </Col>
                  <Col xs={12} xl={8}>
                    <Table hover>
                      <tbody>
                        <tr>
                          <td
                            className="text-secondary"
                            style={{ border: "none" }}
                          >
                            Name:
                          </td>
                          <td style={{ border: "none" }}>
                            <b>
                              {singleKycData?.user_firstname || "--"}{" "}
                              {singleKycData?.user_lastname || "--"}
                            </b>
                          </td>
                        </tr>
                        <tr>
                          <td className="text-secondary">Mobile:</td>
                          <td>
                            <b>{singleKycData?.mobile_no || "--"}</b>
                          </td>
                        </tr>
                        <tr>
                          <td className="text-secondary">Country:</td>
                          <td>
                            <b>{singleKycData?.user_country || "--"}</b>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Col>
                </Row>
                <h6 className="mt-4 mb-1 bold">KYC Info</h6>
                <Row>
                  <Col>
                    <Table bordered hover>
                      <tbody>
                        <tr>
                          <td className="text-secondary">Name:</td>
                          <td>
                            <b>
                              {singleKycData?.firstname || "--"}{" "}
                              {singleKycData?.lastname || "--"}
                            </b>
                          </td>
                        </tr>
                        <tr>
                          <td className="text-secondary">Gender:</td>
                          <td>
                            <b>
                              {singleKycData?.gender == 0 ? "Male" : "Female"}
                            </b>
                          </td>
                        </tr>
                        <tr>
                          <td className="text-secondary">Birthday:</td>
                          <td>
                            <b>
                              {singleKycData?.dob == "0000-00-00 00:00:00" ||
                              !singleKycData?.dob ? (
                                "--"
                              ) : (
                                <>
                                  {singleKycData?.dob &&
                                    dateFormat(
                                      singleKycData?.dob,
                                      "dd-mm-yyyy"
                                    )}
                                </>
                              )}
                            </b>
                          </td>
                        </tr>
                        <tr>
                          <td className="text-secondary">City:</td>
                          <td>
                            <b>{singleKycData?.city || "--"}</b>
                          </td>
                        </tr>
                        <tr>
                          <td className="text-secondary">Country:</td>
                          <td>
                            <b>{singleKycData?.country_code || "--"}</b>
                          </td>
                        </tr>
                        <tr>
                          <td className="text-secondary">
                            Zip Code/Postal Code:
                          </td>
                          <td>
                            <b>{singleKycData?.zip || "--"}</b>
                          </td>
                        </tr>
                        <tr>
                          <td className="text-secondary">
                            <b>
                              {singleKycData?.documents &&
                                singleKycData?.documents.length > 0 &&
                                (singleKycData?.documents[0].type == 0 ? (
                                  <>
                                    <>Passport number:</>
                                  </>
                                ) : (
                                  <>Identity number:</>
                                ))}
                            </b>
                          </td>
                          <td>
                            <b>
                              {singleKycData != undefined &&
                                singleKycData?.documents &&
                                singleKycData?.documents.length > 0 && (
                                  <>
                                    {
                                      singleKycData?.documents[0]
                                        .document_number
                                    }
                                  </>
                                )}
                            </b>
                          </td>
                        </tr>
                        <tr>
                          <td className="text-secondary">Status:</td>
                          <td>{this.kycStatus(singleKycData.status)}</td>
                        </tr>
                        {singleKycData.reason_for_decline &&
                          singleKycData.status !== 1 && (
                            <tr>
                              <td className="text-secondary">
                                Decline Reason:
                              </td>
                              <td>
                                <div
                                  style={{
                                    whiteSpace: "pre-wrap",
                                    wordWrap: "break-word",
                                  }}
                                >
                                  {this.formattedText(
                                    singleKycData.reason_for_decline
                                  )}
                                </div>
                              </td>
                            </tr>
                          )}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    {(singleKycData?.status === 0 ||
                      singleKycData?.status === 3) && (
                      <Button
                        variant="success"
                        style={{ width: "100%" }}
                        className="mb-2"
                        onClick={() =>
                          this.approvepopupopen(singleKycData?.users_id)
                        }
                      >
                        Approve
                      </Button>
                    )}
                  </Col>
                  <Col xs={12}>
                    {(singleKycData?.status === 0 ||
                      singleKycData?.status === 3 ||
                      singleKycData?.status === 1) && (
                      <Button
                        variant="outline-danger"
                        style={{ width: "100%" }}
                        onClick={() =>
                          this.rejectopenpopup(singleKycData?.users_id)
                        }
                      >
                        Reject
                      </Button>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card.Body>
        </Card>

        {this.state.approvepopup && (
          <Modal
            show={this.state.approvepopup}
            onHide={this.handleClose}
            keyboard={false}
            className="logoutModal"
          >
            <Modal.Header closeButton className="pb-0">
              {/* <Modal.Title>Modal title</Modal.Title> */}
            </Modal.Header>
            <Modal.Body>Are you sure want to approve ?</Modal.Body>
            <Modal.Footer className="mx-auto pt-0">
              <Button variant="secondary" onClick={this.handleClose}>
                Cancel
              </Button>
              <Button
                variant="success"
                tag={Link}
                onClick={() => this.approveKycAction(this.state.approvekycid)}
              >
                Approve
              </Button>
            </Modal.Footer>
          </Modal>
        )}
        {this.state.deletedapprovepopup && (
          <Modal
            show={this.state.deletedapprovepopup}
            onHide={this.handleClose}
            keyboard={false}
            className="logoutModal"
          >
            <Modal.Header closeButton className="pb-0">
              {/* <Modal.Title>Modal title</Modal.Title> */}
            </Modal.Header>
            <Modal.Body className="p-0">
              <div className="mb-1">Are you sure want to Reject ?</div>
              <Col>
                {/* <Form.Group> */}
                {/* <Form.Control
                    as="select"
                    defaultValue="Limit"
                    name="limit"
                    id="limit"
                    //value={filterData.limit}
                    onChange={(e) => this.setState({ kycRejectReason: e.target.value })}
                  // disabled={enableFilterInput}
                  > */}
                {/* <Form.Control
                    as="textarea" rows={5}  
                    onChange={(e) => this.setState({ kycRejectReason: e.target.value })}
                   /> */}

                <ContentEditable
                  // className="editable"
                  tagName="pre"
                  html={this.state.html} // innerHTML of the editable div
                  disabled={!this.state.editable} // use true to disable edition
                  onChange={this.handleChangeeditor} // handle innerHTML change
                  onBlur={this.sanitize}
                  style={{
                    border: "1px dashed #aaa",
                    borderRadius: 4,
                    minHeight: 150,
                  }}
                />

                {/* {REJECT_STATUS.map((limit) => {
                      return <option value={limit.value}>{limit.name}</option>;
                    })} */}
                {/* </Form.Control> */}
                {/* {console.log("this is rwject Query",this.state.kycRejectReason)} */}
                {/* </Form.Group> */}
              </Col>
            </Modal.Body>
            <Modal.Footer className="mx-auto pt-0">
              <Button variant="secondary" onClick={this.handleClose}>
                Cancel
              </Button>
              <Button
                variant="danger"
                tag={Link}
                onClick={() =>
                  this.rejectKycAction(
                    this.state.deletedapproveid,
                    this.state.kycRejectReason
                  )
                }
              >
                Reject
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    singleKycData: state.user.singleKycData,
    /* 
        users: state.user.users,
        singleKycData: state.user.singleKycData */
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    singleKycDataFn: (data) => dispatch(singleKycDataFn(data)),
    kycActionFn: (data, history) => dispatch(kycActionFn(data, history)),
    getImage: (data) => dispatch(getImage(data)),
    /* 
        getAllUsersFn: (data) => dispatch(getAllUsersFn(data)),
        editUserInfo: (data) => dispatch(editUserInfo(data)),
        singleKycDataFn: (data) => dispatch(singleKycDataFn(data)), */
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditUser);
