import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import EditCurrencyForm from "../currency/editCurrencyForm";

export default class currencyEdit extends React.Component {
  editUserInfo = (data) => {
    this.props.handleEditUserForm(data);
  };

  render() {
    return (
      <>
        <Container fluid className={"py-4"}>
          <Card style={{ minHeight: "50dvh" }}>
            <Card.Body>
              <Row className="page-header pb-3 justify-content-space-between">
                <Col>
                  <h3 className="page-title">{"Edit Fee"}</h3>
                </Col>
              </Row>
              <EditCurrencyForm handleEditUserForm={this.editUserInfo} />
            </Card.Body>
          </Card>
        </Container>
      </>
    );
  }
}
