import React from "react";
import {
  Badge,
  Button,
  Card,
  Col,
  Container,
  Dropdown,
  Form,
  Row,
  Table,
} from "react-bootstrap";
import { Icon, Pagination } from "semantic-ui-react";

import dateFormat from "dateformat";
import {
  csvDataFn,
  depositeFilterDataFn,
} from "../../../Redux/Actions/orders/orders.action";
import { getPairListFn } from "../../../Redux/Actions/pair/pair.action";

import "moment/locale/en-gb";
import "rc-datepicker/lib/style.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import { limit, ORDER_STATUS, ORDER_TYPE } from "../../../Constants/constant";
//import "./Users.css";
import { CSVLink } from "react-csv";
import "../../../App.css";
import { convertTZ } from "./../../common/helper";

class Users extends React.Component {
  constructor() {
    super();
    this.state = {
      disabled: true,
      totalDataCount: 0,
      enableFilterInput: true,
      csvListData: [],
      stateCsvData: [],
      page: 1,
      coin: "",
      currencyCoins: "",
      currencyType: "",
      filterData: {
        email: "",
        page: 1,
        pagination: 1,
        pair: "",
        search: "",
        status: "",
        type: "",
        limit: limit,
        fromDate: "",
        toDate: "",
      },
      // add is loading csv
      isLoadingCSV: false,
      isDateRangeInvalid: false,
    };
    this.csvInstance = React.createRef();
  }

  componentDidMount = () => {
    let { getPairListFn, depositeFilterDataFn } = this.props;
    getPairListFn();
    depositeFilterDataFn(this.state.filterData);
    // .then((res) => {
    //   this.CsvOrderListing();
    // });

    // this.searchValues();
  };

  CsvOrderListing = () => {
    this.setState({ isLoadingCSV: true });

    let { filterData } = this.state;
    let { csvDataFn, totalRecords } = this.props;
    let filterDatas = {
      email: filterData.email,
      page: 1,
      pagination: 1,
      pair: filterData.pair,
      search: filterData.search,
      status: filterData.status,
      type: filterData.type,
      fromDate: filterData.fromDate
        ? dateFormat(filterData.fromDate, "yyyy-mm-dd")
        : "",
      toDate: filterData.toDate
        ? dateFormat(filterData.toDate, "yyyy-mm-dd 23:59:59")
        : "",
      limit: totalRecords,
    };
    csvDataFn(filterDatas)
      .then((res) => {
        const formattedData = res?.orderlist.map((stateData, index) => ({
          "#": index + 1,
          Order: stateData.id,
          Date: dateFormat(
            convertTZ(stateData?.created_at, "Asia/Vientiane"),
            "dd/mm/yyyy h:MM:ss TT"
          ),
          "Email Id": stateData.email,
          Side: stateData?.side == 0 ? "Buy" : "Sell",
          Type: stateData?.type == 0 ? "Limit" : "Market",
          Pair: stateData?.symbol.toUpperCase(),
          Fee: stateData.fee,
          Filled: stateData.filled_amount,
          "Total Units": stateData.total,
          "Amount/Qty": stateData.quantity,
          Price:
            stateData?.type == 1 ? stateData?.executed_price : stateData.price,
          "Execution Price": stateData.executed_price,
          "Updated At": dateFormat(
            convertTZ(stateData?.updated_at, "Asia/Vientiane"),
            "dd/mm/yyyy h:MM:ss TT"
          ),
          Status:
            stateData.status == 1
              ? "Open"
              : stateData.status == 2
              ? "Cancelled"
              : stateData.status == 3
              ? "Completed"
              : stateData.status == 0 && "Pending",
        }));
        this.setState({ csvListData: formattedData, isLoadingCSV: false });
      })
      .catch((err) => {
        this.setState({ isLoadingCSV: false });
      });
  };

  clearSearch = () => {
    let { filterData } = this.state;
    filterData.email = "";
    filterData.pair = "";
    filterData.status = "";
    filterData.type = "";
    filterData.fromDate = "";
    filterData.toDate = "";
    // this.state.currencyCoins = "";
    this.setState({ currencyCoins: "" });
    this.setState({ filterData: filterData, isDateRangeInvalid: false });
    let data = {
      page: 1,
      pagination: 1,
      search: "",
      status: "",
      type: "",
      pair: "",
      limit: limit,
      email: "",
      fromDate: "",
      toDate: "",
    };
    this.props.depositeFilterDataFn(data);
    // .then((res) => {
    //   this.CsvOrderListing();
    // });
  };
  // handle search
  handleSearch = () => {
    // set date range valid
    const { fromDate, toDate } = this.state;
    if ((fromDate && toDate) || (!fromDate && !toDate)) {
      this.setState(
        { ...this.state, page: 1, isDateRangeInvalid: false },
        () => {
          this.searchValues();
        }
      );
      return;
    }
    this.setState({ ...this.state, isDateRangeInvalid: true });
    // this.setState({ ...this.state, page: 1 }, () => {
    //   this.searchValues();
    // });
  };
  // on change page size
  handleChangePageSize = (pageSize) => {
    this.setState(
      { filterData: { ...this.state.filterData, limit: pageSize } },
      () => {
        // call back to load new user list with filter state
        this.searchValues();
      }
    );
  };

  pageChange = (e, data) => {
    let { filterData } = this.state;
    let page = data.activePage;
    this.setState(
      {
        page: page,
        filterData: {
          ...filterData,
          page,
        },
      },
      () => this.searchValues()
    );
    // filterData.page = page;
    // setTimeout(() => {
    //   this.searchValues();
    // });
  };

  toggleCalender = () => {
    document.querySelector("#to_calender").style.display = "none";
    let calender = document.querySelector("#calender");
    if (calender.style.display === "none") {
      calender.style.display = "block";
    } else {
      calender.style.display = "none";
    }
  };

  toggleToCalender = () => {
    document.querySelector("#calender").style.display = "none";
    let calender = document.querySelector("#to_calender");
    if (calender.style.display === "none") {
      calender.style.display = "block";
    } else {
      calender.style.display = "none";
    }

    // filter function will be added here
  };

  handleFromByDate = (date) => {
    let from_date = `${date.getFullYear()}-${
      date.getMonth() + 1
    }-${date.getDate()}`;

    this.setState({ fromDate: from_date });

    document.querySelector("#calender").style.display = "none";
    document.getElementById("from_date").value = from_date;
    document.getElementById("from_error").style.display = "none";

    // filter function will be added here
  };

  handleToByDate = (date) => {
    let to_date = `${date.getFullYear()}-${
      date.getMonth() + 1
    }-${date.getDate()}`;

    this.setState({ toDate: to_date });

    document.querySelector("#to_calender").style.display = "none";
    document.getElementById("to_date").value = to_date;
    document.getElementById("to_error").style.display = "none";
    this.getValue(date);
  };

  searchValuesbegin = () => {
    this.state.filterData.page = 1;
    // console.log("first page")
    setTimeout(() => {
      this.searchValues();
    });
    // console.log("end page")
  };

  searchValues = () => {
    let { depositeFilterDataFn } = this.props;
    // console.log(this.state.filterData, "this.state.filterData");
    let { filterData } = this.state;

    let filterDatas = {
      email: filterData.email,
      page: filterData.page,
      pagination: 1,
      pair: filterData.pair,
      search: filterData.search,
      status: filterData.status,
      type: filterData.type,
      fromDate: filterData.fromDate
        ? dateFormat(filterData.fromDate, "yyyy-mm-dd")
        : "",
      toDate: filterData.toDate
        ? dateFormat(filterData.toDate, "yyyy-mm-dd 23:59:59")
        : "",
      limit: filterData.limit,
    };
    depositeFilterDataFn(filterDatas);
    // .then((res) => {
    //   this.CsvOrderListing();
    // });
  };

  getValue = (e) => {
    if (e == "") {
      this.setState({ disabled: true });
    } else {
      this.setState({ disabled: false });
    }
  };

  getFilterInputData = (e, type) => {
    let { filterData } = this.state;
    this.setState({
      // currencyCoins: e,
      currencyType: e,
    });

    //filterData.coin = e.target.value;
    if (type == "pairList") filterData.pair = e.target.value;
    else if (type == "email") filterData.email = e.target.value;
    else if (type == "status") filterData.status = e.target.value;
    else if (type == "type") filterData.type = e.target.value;
    else if (type == "fromDate") filterData.fromDate = e;
    else if (type == "toDate") filterData.toDate = e;

    // else if (type == "status") {
    //   if (e.target.value == "") this.getValue(".");
    //   filterData.status = e.target.value;
    // }
    //console.log("filterData", filterData);
    this.setState({ filterData: filterData });
    if (type == "fromDate" || type == "toDate") {
      this.getValue(e);
    } else {
      this.getValue(e.target.value);
    }
  };

  getCSVList = () => {
    // console.log(this.state.csvListData, "CSVlist data");
    let statementCsvData = [];
    if (
      this.state.csvListData !== undefined &&
      this.state.csvListData.length > 0
    ) {
      this.state.csvListData.map((stateData) => {
        let data = {
          Order: stateData.id,
          Date: dateFormat(
            convertTZ(stateData?.created_at, "Asia/Vientiane"),
            "dd/mm/yyyy"
          ),
          Time: dateFormat(
            convertTZ(stateData?.created_at, "Asia/Vientiane"),
            "h:MM:ss TT"
          ),
          "Email Id": stateData.email,
          Side: stateData?.side == 0 ? "Buy" : "Sell",
          Type: stateData?.type == 0 ? "Limit" : "Market",
          Pair: stateData?.symbol.toUpperCase(),
          Fee: stateData.fee,
          Filled: stateData.filled_amount,
          "Total Units": stateData.total,
          "Amount/Qty": stateData.quantity,
          Price:
            stateData?.type == 1 ? stateData?.executed_price : stateData.price,
          "Excecution Price": stateData.executed_price,

          Status:
            stateData.status == 1
              ? "Open"
              : stateData.status == 2
              ? "Cancelled"
              : stateData.status == 3
              ? "Completed"
              : stateData.status == 0 && "Pending",
        };

        statementCsvData.push(data);
      });
      this.setState({
        stateCsvData: statementCsvData,
        //dataLength: nextProps.customerDataLength,
      });
    } else {
      this.setState({
        stateCsvData: "",
        // dataLength: nextProps.customerDataLength,
      });
    }
  };

  render() {
    let { pairListData, totalRecords, orderListData } = this.props;
    return (
      <div>
        <Container fluid className={"py-4"}>
          <Card>
            <Card.Body>
              <Row className="page-header pb-3 justify-content-space-between">
                <Col>
                  <h3 className="page-title">{"Order Listing"}</h3>
                </Col>
                <Col className={"d-flex justify-content-end"}>
                  {/* react csv */}
                  <>
                    <div onClick={this.CsvOrderListing}>
                      <Button
                        variant="white"
                        disabled={this.state.isLoadingCSV}
                      >
                        <Icon name="download" />
                        {this.state.isLoadingCSV
                          ? "Loading..."
                          : "Export as CSV"}
                      </Button>
                    </div>
                    {this.state.stateCsvData.length ? (
                      <CSVLink
                        data={this.state.stateCsvData}
                        filename={`order_listing${new Date().toISOString()}.csv`}
                        ref={this.csvInstance}
                      />
                    ) : undefined}
                  </>
                </Col>
              </Row>
              <Row>
                <Col lg={3} md={4} xs={12}>
                  <Form.Group controlId="formGridState">
                    <Form.Label>Order Type</Form.Label>
                    <Form.Control
                      as="select"
                      defaultValue="Status"
                      name="status"
                      id="status"
                      value={this.state.filterData.type}
                      onChange={(e) => this.getFilterInputData(e, "type")}
                    >
                      {this.state.filterData.type == "" && (
                        <option className="d-none" value="">
                          Type
                        </option>
                      )}
                      {ORDER_TYPE.map((type, i) => {
                        return (
                          <option key={i} value={type.value}>
                            {type.name}
                          </option>
                        );
                      })}
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col lg={3} md={4} xs={12}>
                  <Form.Group controlId="formGridState">
                    <Form.Label>Coin</Form.Label>

                    <Form.Control
                      as="select"
                      // value={this.state.filterData.pair}
                      name="coin"
                      id="coin"
                      value={
                        this.state.filterData.pair
                          ? this.state.filterData.pair
                          : "Select Coin"
                      }
                      onChange={(e) => this.getFilterInputData(e, "pairList")}
                    >
                      {this.state.currencyCoins == "" && (
                        <option className="d-none" value="">
                          Select Coin
                        </option>
                      )}
                      {pairListData != undefined &&
                        pairListData &&
                        pairListData.map((pairList, index) => {
                          return (
                            <option
                              key={index}
                              value={pairList.tarding_pair_key}
                            >
                              {pairList.pair}
                            </option>
                          );
                        })}
                    </Form.Control>
                  </Form.Group>
                </Col>

                <Col lg={3} md={4} xs={12}>
                  <Form.Group>
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="search"
                      name="email"
                      id="email"
                      value={this.state.filterData.email}
                      placeholder="Email"
                      onChange={(e) => this.getFilterInputData(e, "email")}
                    />
                  </Form.Group>
                </Col>
                <Col lg={3} md={4} xs={12}>
                  <Form.Group>
                    <Form.Label>From Date</Form.Label>
                    <DatePicker
                      dateFormat="dd-MM-yyyy"
                      selected={
                        this.state.filterData.fromDate
                          ? this.state.filterData.fromDate
                          : ""
                      }
                      placeholderText="DD/MM/YYYY"
                      className="form-control"
                      onChange={(e) => this.getFilterInputData(e, "fromDate")}
                    />
                    <Form.Text type="danger" muted>
                      {this.state.isDateRangeInvalid &&
                      !this.state.filterData.fromDate ? (
                        <span style={{ color: "red" }}>
                          Please select from date
                        </span>
                      ) : (
                        ""
                      )}
                    </Form.Text>
                  </Form.Group>
                </Col>
                <Col lg={3} md={4} xs={12}>
                  <Form.Group>
                    <Form.Label>To Date</Form.Label>
                    <DatePicker
                      disabled={!this.state.filterData.fromDate}
                      minDate={new Date(this.state.filterData.fromDate)}
                      dateFormat="dd-MM-yyyy"
                      selected={
                        this.state.filterData.toDate
                          ? this.state.filterData.toDate
                          : ""
                      }
                      placeholderText="DD/MM/YYYY"
                      className="form-control"
                      onChange={(e) => this.getFilterInputData(e, "toDate")}
                    />
                    <Form.Text muted>
                      {this.state.isDateRangeInvalid &&
                      !this.state.filterData.toDate ? (
                        <span style={{ color: "red" }}>
                          Please select to date
                        </span>
                      ) : (
                        ""
                      )}
                    </Form.Text>
                  </Form.Group>
                </Col>
                <Col lg={3} md={4} xs={12}>
                  <Form.Group controlId="formGridState">
                    <Form.Label>Status</Form.Label>
                    <Form.Control
                      as="select"
                      defaultValue="Status"
                      name="status"
                      id="status"
                      value={this.state.filterData.status}
                      onChange={(e) => this.getFilterInputData(e, "status")}
                    >
                      {this.state.filterData.status == "" && (
                        <option className="d-none" value="">
                          All
                        </option>
                      )}
                      {ORDER_STATUS.map((status) => {
                        return (
                          <option value={status.value}>{status.name}</option>
                        );
                      })}
                    </Form.Control>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col
                // className={"d-flex justify-content-center mt-2"}
                >
                  <Button
                    variant="primary"
                    type="submit"
                    // disabled={this.state.disabled}
                    onClick={this.handleSearch}
                  >
                    Search
                  </Button>

                  <Button
                    variant="danger"
                    className="ml-2"
                    onClick={this.clearSearch}
                  >
                    Reset
                  </Button>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <Card className="mt-4">
            <Card.Body>
              <Table striped bordered hover responsive size="sm">
                <thead>
                  <tr
                    style={{
                      height: "3rem",
                      color: "#3d5170",
                    }}
                  >
                    <th className="text-center align-middle">#</th>
                    <th className="text-center align-middle">Order</th>
                    <th className="align-middle">Date</th>
                    {/* <th className="align-middle">Time</th> */}
                    <th className="align-middle">Email ID</th>
                    <th className="align-middle">Side</th>
                    <th className="align-middle">Type</th>
                    <th className="align-middle">Pair</th>
                    <th className="align-middle">Fee</th>
                    <th className="align-middle">Filled</th>
                    <th className="align-middle">Total Units</th>
                    <th className="align-middle">Amount/Qty</th>
                    <th className="align-middle">Price</th>
                    <th className="align-middle">Execution Price</th>
                    <th className="align-middle">Updated At</th>
                    <th className="align-middle">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {orderListData.length > 0 ? (
                    orderListData.map((depositeData, key) => {
                      return (
                        <tr>
                          <td className="text-center align-middle">
                            {(this.state.page - 1) *
                              this.state.filterData.limit +
                              (key + 1)}
                          </td>
                          <td className="text-center align-middle">
                            {depositeData.id}
                          </td>
                          <td className="align-middle">
                            {dateFormat(
                              convertTZ(
                                depositeData?.created_at,
                                "Asia/Vientiane"
                              ),
                              "dd/mm/yyyy h:MM:ss TT"
                            )}
                          </td>
                          {/* <td className="align-middle">
                          {dateFormat(
                            convertTZ(
                              depositeData?.created_at,
                              "Asia/Vientiane"
                            ),
                            "h:MM:ss TT"
                          )}
                        </td> */}
                          <td className="align-middle">
                            {depositeData?.email}
                          </td>
                          {depositeData?.side == 0 ? (
                            <td>Buy</td>
                          ) : (
                            <td>Sell</td>
                          )}
                          {depositeData?.type == 0 ? (
                            <td>Limit</td>
                          ) : (
                            <td>Market</td>
                          )}
                          <td className="align-middle">
                            {depositeData?.symbol.toUpperCase()}
                          </td>
                          <td className="align-middle">{depositeData?.fee}</td>
                          <td className="align-middle">
                            {depositeData?.filled_amount}
                          </td>
                          <td className="align-middle">
                            {depositeData?.total}
                          </td>
                          <td className="align-middle">
                            {depositeData?.quantity}
                          </td>
                          {depositeData?.type == 1 ? (
                            <td className="align-middle">
                              {depositeData?.executed_price}
                            </td>
                          ) : (
                            <td className="align-middle">
                              {depositeData?.price}
                            </td>
                          )}
                          <td className="text-center align-middle">
                            {depositeData?.executed_price
                              ? depositeData?.executed_price
                              : "--"}
                          </td>
                          <td className="align-middle">
                            {dateFormat(
                              convertTZ(
                                depositeData?.updated_at,
                                "Asia/Vientiane"
                              ),
                              "dd/mm/yyyy h:MM:ss TT"
                            )}
                          </td>
                          <td className="align-middle">
                            {depositeData.status == 1 ? (
                              <Badge variant="success">Open</Badge>
                            ) : depositeData.status == 2 ? (
                              <Badge variant="danger">Cancelled</Badge>
                            ) : depositeData.status == 0 ? (
                              <Badge variant="warning">Pending</Badge>
                            ) : depositeData.status == 3 ? (
                              <Badge variant="success">Completed</Badge>
                            ) : (
                              ""
                            )}
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr style={{ height: "8rem" }}>
                      <td colSpan="15" className="text-center align-middle">
                        No Record Found
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
              <Row className="justify-content-space-between mt-2">
                <Col>
                  <Dropdown>
                    <Dropdown.Toggle
                      size="sm"
                      variant="outline-secondary"
                      id="pagesize-dropdown"
                    >
                      <Icon name="caret up" />
                      {`Show ${this.state.filterData.limit} items/page`}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() => this.handleChangePageSize(50)}
                      >
                        Show 50 items
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => this.handleChangePageSize(100)}
                      >
                        Show 100 items
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => this.handleChangePageSize(150)}
                      >
                        Show 150 items
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => this.handleChangePageSize(250)}
                      >
                        Show 250 items
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => this.handleChangePageSize(500)}
                      >
                        Show 500 items
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
                <Col className={"d-flex justify-content-end"}>
                  <Pagination
                    size="mini"
                    defaultActivePage={1}
                    activePage={this.state.page}
                    onPageChange={this.pageChange}
                    ellipsisItem={{
                      content: <Icon name="ellipsis horizontal" />,
                      icon: true,
                    }}
                    firstItem={{
                      content: <Icon name="angle double left" />,
                      icon: true,
                    }}
                    lastItem={{
                      content: <Icon name="angle double right" />,
                      icon: true,
                    }}
                    prevItem={{
                      content: <Icon name="angle left" />,
                      icon: true,
                    }}
                    nextItem={{
                      content: <Icon name="angle right" />,
                      icon: true,
                    }}
                    totalPages={Math.ceil(
                      totalRecords / this.state.filterData.limit
                    )}
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    orderListData: state.orders.orderListData,
    pairListData: state.Pair.pairListData,
    totalRecords: state.orders.totalRecords,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPairListFn: (data) => dispatch(getPairListFn(data)),
    depositeFilterDataFn: (data) => dispatch(depositeFilterDataFn(data)),
    csvDataFn: (data) => dispatch(csvDataFn(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);
