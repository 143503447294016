import React from "react";
import { getImageUrlApi } from "../../../Services/API/orders.service";

import {
  Badge,
  Button,
  Card,
  Col,
  Container,
  Dropdown,
  Form,
  Row,
  Table,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { Icon, Pagination } from "semantic-ui-react";

import dateFormat from "dateformat";
import {
  getSwapOrderCSVListFn,
  getSwapOrderListFn,
  verifiedOrderFn,
} from "../../../Redux/Actions/pair/pair.action";

import "moment/locale/en-gb";
import "rc-datepicker/lib/style.css";
import DatePicker from "react-datepicker";
import { connect } from "react-redux";
import {
  limit,
  MoneyFormatWithoutSmallUnit,
  SWAP_ORDER_STATUS,
} from "../../../Constants/constant";
//import "./Users.css";
import "../../../App.css";

import { CSVLink } from "react-csv";
import { swapPairFn } from "../../../Redux/Actions/currency/currency.action";
import { convertTZ } from "./../../common/helper";

class SwapOrders extends React.Component {
  constructor() {
    super();
    this.state = {
      disabled: true,
      totalDataCount: 0,
      enableFilterInput: true,
      stateCsvData: [],
      page: 1,
      coin: "",
      currencyCoins: "",
      currencyType: "",
      imageUrl: "",
      showImageModal: false,
      filterData: {
        page: 1,
        pagination: 1,
        pair: "",
        search: "",
        status: "",
        type: "",
        fromDate: "",
        toDate: "",
        email: "",
        orderId: "",
        limit: limit,
      },
      // add is loading csv
      isLoadingCSV: false,
      isDateRangeInvalid: false,
    };
    this.csvInstance = React.createRef();
  }

  componentDidMount = () => {
    // console.log("calllledldld");
    // let values = queryString.parse(this.props.location.search);
    // let { getPairListFn } = this.props;
    // let searchedParams = { page: this.state.page };
    this.getSwapOrderListFnData(this.state.filterData);
    let { swapPairFn } = this.props;
    swapPairFn();
    // getAllDepositesFn();
  };

  getSwapOrderListFnData = (data) => {
    let { filterData } = this.state;
    let { getSwapOrderListFn } = this.props;
    let orderData = {
      pair: data.pair,
      email: data.email,
      order_status: data.status,
      page: filterData.page,
      // page:data.email,
      startDate: data.fromDate ? dateFormat(data.fromDate, "yyyy-mm-dd") : "",
      endDate: filterData.toDate
        ? dateFormat(filterData.toDate, "yyyy-mm-dd 23:59:59")
        : "",
      orderId: data.orderId,
      limit: filterData?.limit?.toString(),
    };
    //console.log(data)
    getSwapOrderListFn(orderData);
    // .then((res) => {
    //   // console.log(res);
    //   this.getSwapOrderListCSVFnData(this.state.filterData);
    // });
  };

  getSwapOrderListCSVFnData = (data) => {
    this.setState({ isLoadingCSV: true });

    let { filterData } = this.state;
    let { getSwapOrderCSVListFn, totalRecords } = this.props;
    // console.log("sgsg");
    //console.log(data, "order listing");
    let orderData = {
      pair: data.pair || "",
      order_status: data.status || "",
      // page: data.page,
      page: 1,
      email: data.email || "",
      limit: totalRecords?.toString(),
      startDate: data.fromDate ? dateFormat(data.fromDate, "yyyy-mm-dd") : "",
      endDate: filterData.toDate
        ? dateFormat(filterData.toDate, "yyyy-mm-dd 23:59:59")
        : "",
      currency: data.currency || "",
      orderId: data.orderId || "",
    };
    //console.log(swapOrderListData, "fdffdfdfdfdfff");
    getSwapOrderCSVListFn(orderData)
      .then((res) => {
        let statementCsvData = res.map((stateData, key) => {
          let data = {
            "#": key + 1,

            // "DateTime": dateFormat(stateData?.created_at, "dd-mm-yyyy"),
            DateTime: dateFormat(
              convertTZ(stateData?.created_at, "Asia/Vientiane"),
              "dd-mm-yyyy h:MM:ss TT"
            ),
            UID: stateData.orderId,
            email: stateData.email,
            Pair: stateData.pair_name?.replace("_", "/"),
            Price: stateData.orderValue,
            "Order Quantity": stateData.orderQty,
            Fee: stateData.feeValue,
            "Total Order": stateData.orderTotal,
            "Bank Reference Number": stateData.bankReferenceNo,
            status: stateData.csv_status,
          };
          return data;
        });
        this.setState({
          stateCsvData: statementCsvData,
          isLoadingCSV: false,
        });
      })
      .catch(() => {
        this.setState({
          stateCsvData: "",
          isLoadingCSV: false,
          // dataLength: customerDataLength,
        });
      });
  };
  // trigger csv download after fetched data
  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.stateCsvData.length &&
      this.csvInstance.current &&
      this.csvInstance.current.link
    ) {
      setTimeout(() => {
        this.csvInstance.current.link.click();
        this.setState({ stateCsvData: [], isLoadingCSV: false });
      });
    }
  }
  handleSearch = () => {
    // set date range valid
    const { filterData } = this.state;
    const { fromDate, toDate } = filterData;
    if ((fromDate && toDate) || (!fromDate && !toDate)) {
      this.setState(
        {
          ...this.state,
          page: 1,
          isDateRangeInvalid: false,
          filterData: { ...filterData, page: 1 },
        },
        () => {
          this.searchValues();
        }
      );
      return;
    }
    this.setState({ ...this.state, isDateRangeInvalid: true });

    // this.setState({ filterData: { ...this.state.filterData, page: 1 } }, () => {
    //   this.searchValues();
    // });
  };
  clearSearch = () => {
    let { filterData } = this.state;
    filterData.page = 1;
    filterData.status = "";
    filterData.email = "";
    filterData.fromDate = "";
    filterData.toDate = "";
    filterData.orderId = "";
    filterData.currency = "";
    this.setState({ filterData: filterData, isDateRangeInvalid: false }, () => {
      this.searchValues();
    });
  };

  pageChange = (e, data) => {
    this.setState(
      { filterData: { ...this.state.filterData, page: data.activePage } },
      () => this.searchValues()
    );
  };

  toggleCalender = () => {
    document.querySelector("#to_calender").style.display = "none";
    let calender = document.querySelector("#calender");
    if (calender.style.display === "none") {
      calender.style.display = "block";
    } else {
      calender.style.display = "none";
    }
  };

  toggleToCalender = () => {
    document.querySelector("#calender").style.display = "none";
    let calender = document.querySelector("#to_calender");
    if (calender.style.display === "none") {
      calender.style.display = "block";
    } else {
      calender.style.display = "none";
    }

    // filter function will be added here
  };

  handleFromByDate = (date) => {
    let from_date = `${date.getFullYear()}-${
      date.getMonth() + 1
    }-${date.getDate()}`;

    this.setState({ fromDate: from_date });

    document.querySelector("#calender").style.display = "none";
    document.getElementById("from_date").value = from_date;
    document.getElementById("from_error").style.display = "none";

    // filter function will be added here
  };

  handleToByDate = (date) => {
    let to_date = `${date.getFullYear()}-${
      date.getMonth() + 1
    }-${date.getDate()}`;

    this.setState({ toDate: to_date });

    document.querySelector("#to_calender").style.display = "none";
    document.getElementById("to_date").value = to_date;
    document.getElementById("to_error").style.display = "none";
    this.getValue(date);
  };

  // on change page size
  handleChangePageSize = (pageSize) => {
    this.setState(
      { filterData: { ...this.state.filterData, limit: pageSize } },
      () => {
        // call back to load new user list with filter state
        this.searchValues();
      }
    );
  };
  searchValues = () => {
    this.getSwapOrderListFnData(this.state.filterData);
  };

  getValue = (e) => {
    if (e == "") {
      this.setState({ disabled: true });
    } else {
      this.setState({ disabled: false });
    }
  };

  getFilterInputData = (e, type) => {
    let { filterData } = this.state;
    this.setState({
      currencyCoins: e,
      currencyType: e,
    });
    //this.getValue(e.target.value);
    //filterData.coin = e.target.value;
    if (type == "pair") filterData.pair = e.target.value;
    else if (type == "email") filterData.email = e.target.value;
    else if (type == "status") filterData.status = e.target.value;
    else if (type == "type") filterData.type = e.target.value;
    else if (type == "orderId") filterData.orderId = e.target.value;
    else if (type == "fromDate") filterData.fromDate = e;
    else if (type == "toDate") filterData.toDate = e;
    // else if (type == "status") {
    //   if (e.target.value == "") this.getValue(".");
    //   filterData.status = e.target.value;
    // }
    this.setState({ filterData: filterData });
  };

  verifiedOrder = (verifyData) => {
    let { verifiedOrderFn } = this.props;
    // let verifyData

    verifiedOrderFn(verifyData).then((res) => {
      // console.log(res, "response data");
      this.getSwapOrderListFnData(this.state.filterData);
    });
  };

  getUrl = async (data) => {
    let FileData = {
      fileId: data,
    };
    let options = {
      "api-access-token": this.props.token,
    };
    await getImageUrlApi(FileData, options)
      .then((res) => {
        this.setState({ imageUrl: res?.data?.data?.file?.fullpath });
        this.setState({ showImageModal: true });
      })
      .catch((error) => {
        alert("no image found !");
      });
  };

  render() {
    let { totalRecords, swapOrderListData } = this.props;
    return (
      <Container fluid className={"py-4"}>
        <Card>
          <Card.Body>
            <Row className="page-header pb-3 justify-content-space-between">
              <Col>
                <h3 className="page-title">{"Buy Crypto Listing"}</h3>
              </Col>
              <Col className={"d-flex justify-content-end"}>
                {/* <ImportCsv
                    stateCsvData={this.state.stateCsvData}
                    file_name={"Submitted Kyc"}
                  /> */}
                {/* react csv */}
                <>
                  <div onClick={this.getSwapOrderListCSVFnData}>
                    <Button variant="white" disabled={this.state.isLoadingCSV}>
                      <Icon name="download" />
                      {this.state.isLoadingCSV ? "Loading..." : "Export as CSV"}
                    </Button>
                  </div>
                  {this.state.stateCsvData.length ? (
                    <CSVLink
                      data={this.state.stateCsvData}
                      filename={`Buy_Crypto_${new Date().toISOString()}.csv`}
                      ref={this.csvInstance}
                    />
                  ) : undefined}
                </>
              </Col>
            </Row>
            <Row>
              <Col lg={3} md={4} xs={12}>
                <Form.Group>
                  <Form.Label>Coin</Form.Label>
                  <Form.Control
                    as="select"
                    defaultValue={this.state.filterData.pair}
                    value={this.state.filterData.pair}
                    name="coin"
                    id="coin"
                    onChange={(e) => this.getFilterInputData(e, "pair")}
                  >
                    {this.state.filterData.pair == "" && (
                      <option className="d-none" value="">
                        Select Pair
                      </option>
                    )}
                    {this.props.swapPairData != undefined &&
                      this.props.swapPairData &&
                      this.props.swapPairData.map((coins, index) => {
                        return (
                          <option key={index} value={coins.api_pair}>
                            {coins.api_pair}
                          </option>
                        );
                      })}
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col lg={3} md={4} xs={12}>
                <Form.Group>
                  <Form.Label>From Date</Form.Label>
                  <DatePicker
                    dateFormat="dd-MM-yyyy"
                    className="form-control"
                    selected={
                      this.state.filterData.fromDate
                        ? this.state.filterData.fromDate
                        : ""
                    }
                    placeholderText="DD/MM/YYYY"
                    onChange={(e) => this.getFilterInputData(e, "fromDate")}
                  />
                  <Form.Text type="danger" muted>
                    {this.state.isDateRangeInvalid &&
                    !this.state.filterData.fromDate ? (
                      <span style={{ color: "red" }}>
                        Please select from date
                      </span>
                    ) : (
                      ""
                    )}
                  </Form.Text>
                </Form.Group>
              </Col>
              <Col lg={3} md={4} xs={12}>
                <Form.Group>
                  <Form.Label>To Date</Form.Label>
                  <DatePicker
                    disabled={!this.state.filterData?.fromDate}
                    dateFormat="dd-MM-yyyy"
                    className="form-control"
                    selected={
                      this.state.filterData.toDate
                        ? this.state.filterData.toDate
                        : ""
                    }
                    placeholderText="DD/MM/YYYY"
                    onChange={(e) => this.getFilterInputData(e, "toDate")}
                  />
                  <Form.Text muted>
                    {this.state.isDateRangeInvalid &&
                    !this.state.filterData.toDate ? (
                      <span style={{ color: "red" }}>
                        Please select to date
                      </span>
                    ) : (
                      ""
                    )}
                  </Form.Text>
                </Form.Group>
              </Col>
              <Col lg={3} md={4} xs={12}>
                <Form.Group>
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="search"
                    name="email"
                    id="email"
                    placeholder="Email"
                    value={this.state?.filterData?.email?.trim()}
                    onChange={(e) => this.getFilterInputData(e, "email")}
                    //disabled={enableFilterInput}
                  />
                </Form.Group>
              </Col>
              <Col lg={3} md={4} xs={12}>
                <Form.Group>
                  <Form.Label>Order ID</Form.Label>
                  <Form.Control
                    type="search"
                    name="orderid"
                    id="orderid"
                    placeholder="Order Id"
                    value={this.state.filterData.orderId}
                    onChange={(e) => this.getFilterInputData(e, "orderId")}
                    //disabled={enableFilterInput}
                  />
                </Form.Group>
              </Col>
              <Col lg={3} md={4} xs={12}>
                <Form.Group>
                  <Form.Label>Status</Form.Label>
                  <Form.Control
                    as="select"
                    defaultValue="Status"
                    name="status"
                    id="status"
                    value={this.state.filterData.status}
                    onChange={(e) => this.getFilterInputData(e, "status")}
                  >
                    {/* {this.state.filterData.status == "" && (
                    <option className="d-none" value="">
                      Status
                    </option>
                  )} */}
                    {SWAP_ORDER_STATUS.map((status) => {
                      return (
                        <option value={status.value}>{status.name}</option>
                      );
                    })}
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col
              //  className={"d-flex justify-content-center mt-2"}
              >
                <Button
                  variant="primary"
                  type="submit"
                  // disabled={this.state.disabled}
                  onClick={this.handleSearch}
                >
                  Search
                </Button>

                <Button
                  variant="danger"
                  className="ml-2"
                  onClick={this.clearSearch}
                >
                  Reset
                </Button>
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <Card className="mt-4">
          <Card.Body>
            <Table striped bordered hover responsive size="sm">
              <thead>
                <tr
                  style={{
                    height: "3rem",
                    color: "#3d5170",
                  }}
                >
                  <th className="align-middle">#</th>
                  <th className="align-middle">DateTime</th>
                  {/* <th className="align-middle">Time</th> */}
                  <th className="align-middle">Email ID</th>
                  <th className="text-center align-middle">Pair</th>
                  <th className="text-center align-middle">Price</th>
                  <th className="text-center align-middle">Order Quantity</th>
                  <th className="text-center align-middle">Fee</th>
                  <th className="text-center align-middle">Total Order</th>
                  <th className="align-middle">Bank Ref No.</th>
                  <th className="align-middle">UID</th>

                  <th className="align-middle">Status</th>
                  {/* <th className="align-middle">Action</th> */}
                </tr>
              </thead>
              <tbody>
                {swapOrderListData && swapOrderListData.length > 0 ? (
                  swapOrderListData.map((depositeData, key) => {
                    return (
                      <tr key={key}>
                        <td className="text-center align-middle">
                          {(this.state.filterData.page - 1) *
                            this.state.filterData.limit +
                            (parseFloat(key || 0) + 1)}
                        </td>
                        {/* <td className="pl-3 text-nowrap">
                          {dateFormat(
                            convertTZ(
                              depositeData?.created_at,
                              "Asia/Vientiane"
                            ),
                            "dd/mm/yyyy"
                          )}
                        </td> */}

                        <td className="align-middle">
                          {dateFormat(
                            convertTZ(
                              depositeData?.created_at,
                              "Asia/Vientiane"
                            ),
                            "dd/mm/yyyy h:MM:ss TT"
                          )}
                        </td>

                        <td className="align-middle">
                          <Link to={`/auth/trader-view/${depositeData.userId}`}>
                            {depositeData.email}
                          </Link>
                        </td>
                        <td className="text-right align-middle">
                          {depositeData?.pair_name?.replace("_", "/")}
                        </td>
                        <td className="text-right align-middle">
                          {depositeData?.orderValue
                            ? MoneyFormatWithoutSmallUnit(
                                depositeData?.orderValue
                              )
                            : ""}
                        </td>
                        <td className="text-right align-middle">
                          {depositeData.orderQty
                            ? MoneyFormatWithoutSmallUnit(
                                depositeData?.orderQty
                              )
                            : "--"}
                        </td>
                        <td className="text-right align-middle">
                          {depositeData.feeValue
                            ? MoneyFormatWithoutSmallUnit(depositeData.feeValue)
                            : ""}
                        </td>
                        <td className="text-right align-middle">
                          {depositeData?.orderTotal
                            ? MoneyFormatWithoutSmallUnit(
                                depositeData?.orderTotal
                              )
                            : ""}
                        </td>
                        <td className="align-middle">
                          {depositeData.bankReferenceNo || "--"}
                        </td>
                        <td className="align-middle">{depositeData.orderId}</td>
                        <td className="align-middle">
                          {depositeData.status == 2 ? (
                            <Badge variant="success">Completed</Badge>
                          ) : depositeData.status == 1 ? (
                            <Badge variant="warning">Pending</Badge>
                          ) : depositeData.status == 3 ? (
                            <Badge variant="danger">Cancelled</Badge>
                          ) : depositeData.status == 4 ? (
                            <Badge variant="danger">Failed</Badge>
                          ) : depositeData.status == 0 ? (
                            <Badge variant="info">In Progress</Badge>
                          ) : (
                            ""
                          )}
                        </td>

                        {/* <td className="align-middle"> */}
                        {/* <Button
                              className="mr-2"
                              variant="info"
                              onClick={() => this.getUrl(depositeData?.fileId)}
                            >
                              E-Slip
                            </Button> */}
                        {/* {depositeData.status != 1 ? (
                              <Button
                                onClick={() => this.verifiedOrder(depositeData)}
                                variant="success"
                                size="sm"
                              >
                                Verify
                              </Button>
                            ) : null}
                          </td> */}
                      </tr>
                    );
                  })
                ) : (
                  <tr style={{ height: "8rem" }}>
                    <td colSpan="12" className="text-center align-middle">
                      No Record Found
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
            <Row className="justify-content-space-between mt-2">
              <Col>
                <Dropdown>
                  <Dropdown.Toggle
                    size="sm"
                    variant="outline-secondary"
                    id="pagesize-dropdown"
                  >
                    <Icon name="caret up" />
                    {`Show ${this.state.filterData.limit} items/page`}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() => this.handleChangePageSize(50)}
                    >
                      Show 50 items
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => this.handleChangePageSize(100)}
                    >
                      Show 100 items
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => this.handleChangePageSize(150)}
                    >
                      Show 150 items
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => this.handleChangePageSize(250)}
                    >
                      Show 250 items
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => this.handleChangePageSize(500)}
                    >
                      Show 500 items
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
              <Col className={"d-flex justify-content-end"}>
                <Pagination
                  size="mini"
                  activePage={this.state.filterData.page}
                  onPageChange={this.pageChange}
                  ellipsisItem={{
                    content: <Icon name="ellipsis horizontal" />,
                    icon: true,
                  }}
                  firstItem={{
                    content: <Icon name="angle double left" />,
                    icon: true,
                  }}
                  lastItem={{
                    content: <Icon name="angle double right" />,
                    icon: true,
                  }}
                  prevItem={{
                    content: <Icon name="angle left" />,
                    icon: true,
                  }}
                  nextItem={{
                    content: <Icon name="angle right" />,
                    icon: true,
                  }}
                  totalPages={Math.ceil(
                    totalRecords / this.state.filterData.limit
                  )}
                />
              </Col>
            </Row>
          </Card.Body>
        </Card>
        {/* <ImageModal
            image={this.state.imageUrl}
            show={this.state.showImageModal}
            setShow={() => this.setState({ showImageModal: false })}
          /> */}
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    swapOrderListData: state.Pair.swapOrderListData,
    pairListData: state.Pair.pairListData,
    totalRecords: state.Pair.totalRecords,
    token: state.auth.tokens,
    swapPairData: state.currency.swapPairData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // getPairListFn: (data) => dispatch(getPairListFn(data)),
    // depositeFilterDataFn: (data) => dispatch(depositeFilterDataFn(data)),
    verifiedOrderFn: (data) => dispatch(verifiedOrderFn(data)),
    getSwapOrderListFn: (data) => dispatch(getSwapOrderListFn(data)),
    getSwapOrderCSVListFn: (data) => dispatch(getSwapOrderCSVListFn(data)),
    swapPairFn: (data) => dispatch(swapPairFn(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SwapOrders);
